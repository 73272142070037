import { APIError } from "../../../api/shared";
import { useDealChatThreadsQuery } from "../../../hooks";
import { useDealQuery } from "../../../hooks/deals";
import { Link, useParams } from "react-router-dom";
import DeleteDealUserChatThreadButton from "./DeleteChatThreadButton";
import TabLayout from "../../TabLayout";
import DealAppToolbar from "../../DealAppToolbar";
import Table from "../../Table";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
// import DeleteFileButton from "./Del";

type URLParams = {
  orgId: string;
  dealId: string;
};

function _DealUserChatThreadList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealChatThreadsQuery = useDealChatThreadsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    dealChatThreadsQuery.error instanceof APIError &&
    dealChatThreadsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list chat threads."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (dealChatThreadsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing chat threads.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!dealChatThreadsQuery.isLoading) {
    data = dealChatThreadsQuery.data.map((thread) => {
      return [
        <Link
          key={thread.id}
          to={`/orgs/${orgId}/deals/${dealId}/chat-threads-su/${thread.id}`}
          className="text-indigo-600"
        >
          {thread.name || "New Thread"}
        </Link>,
        <p key={`user_email_${thread.id}`}>{thread.user_email}</p>,
        <time key={`created_at_${thread.id}`} dateTime={thread.created_at}>
          {new Date(thread.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions_${thread.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteDealUserChatThreadButton
            orgId={orgId}
            dealId={dealId}
            dealChatThreadId={thread.id}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "User", "Created", ""]}
      data={data}
      noDataIcon={ChatBubbleLeftRightIcon}
      noDataMessage={"You don't have any chat threads yet."}
      noDataButton={<></>}
      isLoading={dealChatThreadsQuery.isLoading}
    />
  );
}

export default function DealUserChatThreadList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const breadcrumbItems = [
    { name: "Deals", href: `/orgs/${orgId}/deals` },
    {
      name: dealQuery.data?.name || "...",
      href: `/orgs/${orgId}/deals/${dealId}`,
    },
    { name: "Chat Threads", href: null },
  ];

  return (
    <TabLayout items={breadcrumbItems} buttons={[]}>
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Files", active: true }]}
        />
        <_DealUserChatThreadList />
      </div>
    </TabLayout>
  );
}
