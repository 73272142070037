import { User } from "../types";
import { readDealFile } from "./dealFiles";
import { baseURL } from "./shared";

export interface FileTablesExportCreate {
  all: boolean;
  table_numbers: number[] | null;
}

function downloadFromURL(url: string, fileName: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

export const createFileTablesExport = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
  data: FileTablesExportCreate,
) => {
  const response = await fetch(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}/tables-exports`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    },
  );
  const file = await readDealFile(user, orgId, dealId, fileId);
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const lastIndex = file.name.lastIndexOf(".");
    const baseName = file.name.substring(0, lastIndex);
    downloadFromURL(url, baseName + " Tables Export" + ".xlsx");
  } else {
    throw new Error(`${response.status}`);
  }
};
