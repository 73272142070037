import OrgUsersList from "./OrgUsersList";

interface Props {
  orgId: string;
}
export default function OrgUsers({ orgId }: Props) {
  return (
    <div>
      <div className="flex">
        <p className="text-sm max-w-lg text-gray-700">
          To add new users contact your Capsa AI representative.
        </p>
        <div className="ml-auto" />
      </div>
      <OrgUsersList orgId={orgId} />
    </div>
  );
}
