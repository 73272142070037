import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import {
  CreateSharepointSyncJobResponse,
  SharepointSyncJob,
} from "../types/sharepoint";

interface CreateSharepointSyncJobBody {
  external_folder_ids: string[];
  external_file_ids: string[];
}

export const createSharepointSyncJob = async (
  user: User,
  orgId: string,
  dealId: string,
  externalFolderIds: string[],
  externalFileIds: string[],
): Promise<CreateSharepointSyncJobResponse> => {
  const body: CreateSharepointSyncJobBody = {
    external_folder_ids: externalFolderIds,
    external_file_ids: externalFileIds,
  };
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/sharepoint/sync-jobs`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }),
    true,
  );
};

export const listSharepointSyncJobs = async (
  user: User,
  orgId: string,
  dealId: string,
  jobStatus?: ("pending" | "in_progress" | "completed" | "failed")[],
): Promise<SharepointSyncJob[]> => {
  const url = new URL(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/sharepoint/sync-jobs`,
  );
  if (jobStatus) {
    jobStatus.forEach((s) => url.searchParams.append("job_status", s));
  }
  return await resolveFetch(
    fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const deleteSharepointSyncJob = async (
  user: User,
  orgId: string,
  dealId: string,
  syncJobId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/sharepoint/sync-jobs/${syncJobId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
