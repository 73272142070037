import { getCompanyHeirarchy } from "@/features/registrySearch/api/companyHeirarchy";
import { CompanyNameSuggestion } from "@/features/companies/types/company";
import { useAuth, defaultRetryFunc } from "@/hooks/shared";
import { assertUserIsAuthenticated } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const useCompanyHeirarchy = (company: CompanyNameSuggestion | null) => {
  const { orgId } = useParams();
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "companyNamesQuickSuggestions", company?.graphId],
    queryFn: () =>
      getCompanyHeirarchy(user, orgId || "", company?.graphId || ""),
    enabled: !!user && !!company,
    retry: defaultRetryFunc,
  });
};
