import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  useQuestionTemplateQuery,
  useUpdateQuestionTemplateMutation,
} from "../../hooks/questionTemplates";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import TabLayout from "../TabLayout";
import { QuestionTemplateRead } from "../../api/questionTemplates";

interface QuestionTemplateFormValues {
  name: string;
  questions: { value: string }[];
}

type URLParams = {
  orgId: string;
  questionTemplateId: string;
};

interface FormProps {
  questionTemplate: QuestionTemplateRead;
}

function UpdateForm({ questionTemplate }: FormProps) {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register } =
    useForm<QuestionTemplateFormValues>({
      defaultValues: {
        name: questionTemplate.name,
        questions: questionTemplate.questions.map((q) => ({
          value: q.content,
        })),
      },
    });
  const { fields, append, remove, move, insert } = useFieldArray({
    control,
    name: "questions",
  });
  const mutation = useUpdateQuestionTemplateMutation(
    questionTemplate.org_id,
    questionTemplate.id,
  );

  return (
    <form
      onSubmit={handleSubmit((data) => {
        mutation.mutate(
          {
            name: data.name,
            questions: data.questions.map((question) => ({
              content: question.value,
            })),
          },
          {
            onSuccess: () => {
              navigate(
                `/orgs/${questionTemplate.org_id}/question-templates/${questionTemplate.id}`,
              );
              reset();
            },
          },
        );
      })}
    >
      <div className="flex border-b-[1px] border-gray-200 pb-4">
        <p className="text-sm max-w-lg text-gray-700">
          Update your question template.
        </p>
      </div>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      id="name"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      {...field}
                      value={field.value ?? ""}
                    />
                  )}
                />
              </div>
            </div>

            {fields.map((field, index) => (
              <div className="sm:col-span-6" key={index}>
                <label
                  htmlFor="questions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Question {index + 1}
                </label>
                <div className="mt-1">
                  <div className="flex flex-row">
                    <textarea
                      key={field.id}
                      {...register(`questions.${index}.value`)}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm whitespace-pre-wrap"
                    />
                    <div className="flex">
                      <button
                        title="Move question up"
                        type="button"
                        onClick={() => move(index, index - 1)}
                        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                      >
                        <ArrowUpIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                      </button>
                      <button
                        title="Move question down"
                        type="button"
                        onClick={() => move(index, index + 1)}
                        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                      >
                        <ArrowDownIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                      </button>
                      <button
                        title="Add question below"
                        type="button"
                        onClick={() => insert(index + 1, { value: "" })}
                        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                      >
                        <PlusIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                      </button>
                      <button
                        title="Remove question"
                        type="button"
                        onClick={() => remove(index)}
                        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                      >
                        <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({ value: "" });
                }}
                type="button"
                className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add Question
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(
                `/orgs/${questionTemplate.org_id}/question-templates/${questionTemplate.id}`,
              )
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
}

export default function QuestionTemplatesUpdate() {
  const { orgId, questionTemplateId } = useParams() as URLParams;

  const questionTemplateQuery = useQuestionTemplateQuery(
    orgId,
    questionTemplateId,
  );

  if (questionTemplateQuery.isLoading) {
    return (
      <TabLayout items={[{ name: "Question Templates", href: null }]}>
        <p>Loading...</p>
      </TabLayout>
    );
  }

  if (questionTemplateQuery.error) {
    return (
      <TabLayout items={[{ name: "Question Templates", href: null }]}>
        <p>Error retrieveing question template.</p>
      </TabLayout>
    );
  }

  return (
    <TabLayout
      items={[
        {
          name: "Question Templates",
          href: `/orgs/${orgId}/question-templates`,
        },
        {
          name: questionTemplateQuery.data.name,
          href: `/orgs/${orgId}/question-templates/${questionTemplateId}`,
        },
        {
          name: "Update",
          href: null,
        },
      ]}
    >
      <div className="p-4">
        <UpdateForm questionTemplate={questionTemplateQuery.data} />
      </div>
    </TabLayout>
  );
}
