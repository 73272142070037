import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

function getStyle(index: number, length: number) {
  return index === length - 1
    ? "text-sm font-semibold"
    : "text-sm text-gray-500";
}

export interface BreadcrumbItem {
  name: string;
  href: string | null;
}

interface Props {
  items: BreadcrumbItem[];
}

export default function Breadcrumb({ items }: Props) {
  return (
    <div className="flex flex-row items-center space-x-2 m-4 overflow-auto">
      {items.map((item, index) => (
        <div
          key={index}
          className="flex flex-row items-center space-x-2 whitespace-nowrap"
        >
          {item.href ? (
            <Link to={item.href} className={getStyle(index, items.length)}>
              {item.name}
            </Link>
          ) : (
            <p className={getStyle(index, items.length)}>{item.name}</p>
          )}
          {index < items.length - 1 && (
            <ChevronRightIcon className="h-4 w-4 text-gray-500" />
          )}
        </div>
      ))}
    </div>
  );
}
