import { useAuth0 } from "@auth0/auth0-react";
import { Menu } from "@headlessui/react";
import {
  ArrowUpOnSquareStackIcon,
  Bars3Icon,
  ChevronDoubleLeftIcon,
} from "@heroicons/react/24/outline";
import { Dispatch, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import info from "../../../info";
import { useUpload } from "../../UploadProvider";
import {
  NavigationItem,
  UserNavigationItem,
  classNames,
  sidebarBgColor,
  sidebarHighlightColor,
} from "../common";

interface OpenSidebarUserMenuProps {
  userNavigation: UserNavigationItem[];
}

function OpenSidebarUserMenu({ userNavigation }: OpenSidebarUserMenuProps) {
  const { user } = useAuth0();
  return (
    <Menu>
      <Menu.Button title="Open User Menu">
        <div className="flex flex-row items-center m-4 space-x-3">
          <div className="w-8 h-8 min-h-[2rem] min-w-[2rem] rounded-full bg-indigo-600 text-white flex items-center justify-center">
            {user && user.email && user.email[0].toUpperCase()}
          </div>
          <p className="text-sm truncate">
            {user && user.email && user.email.toLowerCase()}
          </p>
        </div>
      </Menu.Button>
      <Menu.Items className="absolute bottom-16 rounded-md bg-gray-100 flex flex-col mx-2 w-[calc(16rem-1rem)] border border-gray-200 shadow-sm px-1 py-2 space-y-2">
        <p className="text-xs truncate p-1">
          {user && user.email && user.email.toLowerCase()}
        </p>
        <hr className="mx-1" />
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <button
                className={classNames(
                  "flex flex-row items-center space-x-3 hover:bg-white p-1 rounded-md",
                  active ? "bg-white" : "",
                )}
                onClick={() => item.callback()}
              >
                <item.icon className="h-4 w-4 text-gray-500" />
                <p className="text-xs">{item.name}</p>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}

function OpenSidebarUploads() {
  const { uploads } = useUpload();
  if (uploads.size === 0) {
    return <></>;
  }
  return (
    <div className="flex flex-col space-y-1 px-3 pb-3">
      <hr className="mb-3" />
      <div className="flex flex-row items-center space-x-3 p-1">
        <ArrowUpOnSquareStackIcon className="h-6 w-6 text-gray-500" />
        <p className="text-sm">Uploads ({uploads.size})</p>
      </div>
      <div className="rounded-md bg-gray-200 p-1 ">
        <div className="overflow-auto max-h-48">
          {Array.from(uploads.keys()).map((key) => {
            const upload = uploads.get(key);
            if (!upload) {
              return <></>;
            }
            return (
              <div
                key={key}
                className="flex flex-row flex-grow items-center space-x-3 p-1"
              >
                <p className="text-sm truncate" title={key}>
                  {key}
                </p>
                <span className="flex-grow" />
                <p className="text-sm">
                  {Math.round((upload.loadedBytes * 100) / upload.totalBytes)}%
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface OpenSidebarProps {
  setSidebarOpen: Dispatch<boolean>;
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[];
}

function OpenSidebar({
  setSidebarOpen,
  navigation,
  userNavigation,
}: OpenSidebarProps) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${sidebarBgColor} min-h-[4rem] h-16 w-full border-b border-gray-200 fixed`}
      >
        <div className="flex flex-row items-center">
          <button onClick={() => navigate(`/orgs/${info.orgId}`)}>
            <img src="/logo-1.svg" className="h-8 my-4 ml-4" />
          </button>
          <button className="ml-14" onClick={() => setSidebarOpen(false)}>
            <ChevronDoubleLeftIcon className="h-6 w-6 text-gray-500 hover:cursor-pointer" />
          </button>
        </div>
      </div>
      <div
        className={`${sidebarBgColor} w-64 min-h-[16rem] h-[calc(100vh-4rem)] fixed top-16 border-r border-gray-200 flex flex-col z-10`}
      >
        {/* <hr className="mx-4" /> */}
        <div className="flex flex-col mt-3 space-y-1 px-3">
          {navigation.map((item) => (
            <button
              key={item.name}
              className={classNames(
                `flex flex-row items-center space-x-3 hover:${sidebarHighlightColor} p-1 rounded-md`,
                item.current ? sidebarHighlightColor : "",
              )}
              onClick={() => {
                navigate(item.href);
                setSidebarOpen(false);
              }}
            >
              <item.icon className="h-6 w-6 text-gray-500" />
              <p className="text-sm">{item.name}</p>
            </button>
          ))}
        </div>
        <div className="flex-grow" />
        <OpenSidebarUploads />
        <hr className=" mx-4" />
        <OpenSidebarUserMenu userNavigation={userNavigation} />
      </div>
    </>
  );
}

interface ClosedSidebarProps {
  setSidebarOpen: Dispatch<boolean>;
  userNavigation: UserNavigationItem[];
}

function ClosedSidebar({ setSidebarOpen }: ClosedSidebarProps) {
  return (
    <div
      className={`${sidebarBgColor} min-h-[4rem] h-16 w-full border-b border-gray-200 flex flex-row items-center fixed`}
    >
      <div className="flex flex-row items-center">
        <button className="ml-4" onClick={() => setSidebarOpen(true)}>
          <Bars3Icon className="h-6 w-6 text-gray-500" />
        </button>
      </div>
    </div>
  );
}

interface NarrowOrgDashboardLayoutProps {
  navigation: NavigationItem[];
  userNavigation: UserNavigationItem[];
}

export default function NarrowOrgDashboardLayout({
  navigation,
  userNavigation,
}: NarrowOrgDashboardLayoutProps) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div>
      {sidebarOpen ? (
        <>
          <OpenSidebar
            setSidebarOpen={setSidebarOpen}
            navigation={navigation}
            userNavigation={userNavigation}
          />
          <div className="flex-1 pt-16">
            <Outlet />
          </div>
        </>
      ) : (
        <>
          <ClosedSidebar
            setSidebarOpen={setSidebarOpen}
            userNavigation={userNavigation}
          />
          <div className="flex-1 pt-16">
            <Outlet />
          </div>
        </>
      )}
    </div>
  );
}
