import { APIError } from "../../../api/shared";
import DeleteFileButton from "./DeleteFileButton";
import { useDealFilesQuery, useDealQuery } from "../../../hooks/deals";
import { Link, useNavigate, useParams } from "react-router-dom";
import UploadFilesButton from "../UploadFilesButton";
import UploadFolderButton from "../UploadFolderButton";
import { FolderIcon } from "@heroicons/react/24/outline";
import Table from "../../Table";
import React, { useEffect } from "react";
import SyncSharepointModal from "@/features/sharepoint/components/SyncSharepointModal";
import { SharepointSync } from "@/features/sharepoint/components/SharepointSync";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import { Breadcrumbs } from "@capsa-ai/capsa-ui";
import { Heading } from "@capsa-ai/capsa-ui";
import { DocumentUploadActiveJobs } from "./DocumentUploadActiveJobs";
import { useInitSharepoint } from "@/features/sharepoint/hooks/config";

type URLParams = {
  orgId: string;
  dealId: string;
};

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _FilesList() {
  const { orgId, dealId } = useParams() as URLParams;
  const filesQuery = useDealFilesQuery(orgId, dealId);
  useInitSharepoint();
  const { SharepointImportButton } = useSharepointStore();

  // Insufficient permissions
  if (
    filesQuery.error instanceof APIError &&
    filesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list files."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (filesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          <p className="py-4 text-sm text-gray-500">Error listing files.</p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!filesQuery.isLoading) {
    data = filesQuery.data.map((file) => {
      return [
        <Link
          key={file.id}
          to={`/orgs/${orgId}/deals/${dealId}/files/${file.id}`}
          className="text-indigo-600"
        >
          {file.name}
        </Link>,
        <time key={`${file.id}-created_at`} dateTime={file.created_at}>
          {new Date(file.created_at).toLocaleString()}
        </time>,
        <StatusTag key={`${file.id}-status`} status={file.ingestion_status} />,
        <div
          key={`${file.id}-actions`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteFileButton orgId={orgId} dealId={dealId} fileId={file.id} />
        </div>,
      ];
    });
  }

  return (
    <>
      <SyncSharepointModal />
      <div className="flex flex-col gap-1">
        <SharepointSync />
        <DocumentUploadActiveJobs />
      </div>
      <div className="-mt-4">
        <Table
          columnHeaders={["Name", "Uploaded", "Status", ""]}
          data={data}
          noDataIcon={FolderIcon}
          noDataMessage={"You don't have any files yet."}
          noDataButton={
            <div className="flex gap-2 flex-wrap">
              <UploadFilesButton orgId={orgId} dealId={dealId} />
              {SharepointImportButton && <SharepointImportButton />}
            </div>
          }
          isLoading={filesQuery.isLoading}
        />
      </div>
    </>
  );
}

export default function FilesList() {
  const navigate = useNavigate();
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const { SharepointImportButton, reset } = useSharepointStore();

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <>
      <div className="bg min-h-screen relative">
        <div className="flex items-center px-4 h-12 border-b gap-1.5 sticky bg inset-0">
          <div className="pl-3 w-full">
            <Breadcrumbs
              crumbs={[
                { label: "Deals", to: `/orgs/${orgId}/deals` },
                {
                  label: dealQuery.data?.name || "...",
                  to: `/orgs/${orgId}/deals/${dealId}`,
                },
                { label: "Files", to: "" },
              ]}
              onNavigate={navigate}
            />
          </div>
          {SharepointImportButton && <SharepointImportButton />}
          <UploadFolderButton orgId={orgId} dealId={dealId} />
          <UploadFilesButton orgId={orgId} dealId={dealId} />
        </div>
        <div className="flex flex-col px-4 py-6 pb-24 gap-4">
          <div className="pl-3 pb-3 border-b flex gap-4 items-center">
            <Heading>Files</Heading>
          </div>
          <_FilesList />
        </div>
      </div>
    </>
  );
}
