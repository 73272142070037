import info from "../info";

export const baseURL = info.apiURL;

export interface APIErrorJSON {
  type: string;
  title: string;
  status: number;
  detail: string;
}

export interface APIResponseJSON<T> {
  data?: T;
  error?: APIErrorJSON;
  next_cursor?: string;
}

interface NextCursor {
  value: string | null;
}

export interface PaginatedResponse<T> {
  data: T;
  nextCursor: NextCursor;
}

export class APIError extends Error {
  type: string;
  title: string;
  status: number;
  detail: string;

  constructor(type: string, title: string, status: number, detail: string) {
    super(detail);

    // 👇️ because we are extending a built-in class
    Object.setPrototypeOf(this, APIError.prototype);

    this.type = type;
    this.title = title;
    this.status = status;
    this.detail = detail;
  }
}

function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

function toSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

type PlainObject = { [key: string]: unknown };

function mapToCamelCaseRecursive<T>(data: T): T {
  if (Array.isArray(data)) {
    return data.map((item) => mapToCamelCaseRecursive(item)) as unknown as T;
  } else if (typeof data === "object" && data !== null) {
    const newObject: PlainObject = {};
    for (const [inKey, value] of Object.entries(data as PlainObject)) {
      const camelCaseKey = toCamelCase(inKey);
      newObject[camelCaseKey] = mapToCamelCaseRecursive(value);
    }
    return newObject as T;
  } else {
    return data;
  }
}

export function mapToSnakeCase<T>(data: T): T {
  if (Array.isArray(data)) {
    return data.map((item) => mapToSnakeCase(item)) as unknown as T;
  } else if (typeof data === "object" && data !== null) {
    const newObject: PlainObject = {};
    for (const [inKey, value] of Object.entries(data as PlainObject)) {
      const snakeCaseKey = toSnakeCase(inKey);
      newObject[snakeCaseKey] = mapToSnakeCase(value);
    }
    return newObject as T;
  } else {
    return data;
  }
}

export async function resolveFetch<T>(
  responsePromise: Promise<Response>,
  mapToCamelCase: boolean = false,
): Promise<T> {
  const response = await responsePromise;
  const { data, error }: APIResponseJSON<{ [key: string]: unknown }> =
    await response.json();
  if (response.ok) {
    if (mapToCamelCase) {
      return mapToCamelCaseRecursive(data) as unknown as T;
    }
    return data as T;
  } else {
    if (error) {
      throw new APIError(error.type, error.title, error.status, error.detail);
    } else {
      throw new Error(`${response.status}`);
    }
  }
}

export async function resolveFetchPaginated<T>(
  responsePromise: Promise<Response>,
  mapToCamelCase: boolean = false,
): Promise<PaginatedResponse<T>> {
  const response = await responsePromise;
  const {
    data,
    error,
    next_cursor: nextPage,
  }: APIResponseJSON<{ [key: string]: unknown }> = await response.json();
  if (response.ok) {
    if (mapToCamelCase) {
      return {
        data: mapToCamelCaseRecursive(data) as unknown as T,
        nextCursor: { value: nextPage ? nextPage : null },
      };
    }
    return {
      data: data as T,
      nextCursor: { value: nextPage ? nextPage : null },
    };
  } else {
    if (error) {
      throw new APIError(error.type, error.title, error.status, error.detail);
    } else {
      throw new Error(`${response.status}`);
    }
  }
}
