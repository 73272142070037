import { useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  TableAggregationExportCreate,
  createTableAggregationExport,
} from "../api/tableAggregationExports";

export const useCreateTableAggregationExportMutation = (
  orgId: string,
  dealId: string,
  tableAggregationId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: TableAggregationExportCreate) =>
      createTableAggregationExport(
        user,
        orgId,
        dealId,
        tableAggregationId,
        data,
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "tableAggregations",
          tableAggregationId,
          "exports",
        ],
      });
    },
  });
};
