import { useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createFileTablesExport,
  FileTablesExportCreate,
} from "../api/fileTablesExports";

export const useCreateFileTablesExportMutation = (
  orgId: string,
  dealId: string,
  fileId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: FileTablesExportCreate) =>
      createFileTablesExport(user, orgId, dealId, fileId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "files", fileId, "exports"],
      });
    },
  });
};
