import { useParams } from "react-router-dom";
import { useDealFileTablesQuery } from "../../../hooks/dealFileTables";
import { APIError } from "../../../api/shared";
import { FileTableRead, Table } from "../../../api/dealFileTables";
import { useDealFileQuery, useDealQuery } from "../../../hooks/deals";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import info from "../../../info";
import CreateFileTablesExportButton from "./CreateFileTablesExportButton";
import TabLayout from "../../TabLayout";
import { CheckBox } from "../../base/CheckBox";
import { useEffect, useRef } from "react";

type URLParams = {
  orgId: string;
  dealId: string;
  fileId: string;
};

function CopyToClipboard({ table }: { table: Table }) {
  const [showCopied, setShowCopied] = useState(false);
  const posthog = usePostHog();
  const { user } = useAuth0();

  const tableToText = (table: Table) => {
    const array = [];
    for (let i = 0; i < table.row_count; i++) {
      const row = [];
      for (let j = 0; j < table.column_count; j++) {
        row.push("");
      }
      array.push(row);
    }
    for (const cell of table.cells) {
      array[cell.row_index][cell.column_index] = cell.content;
    }
    return array
      .map((row) => row.map((cell) => `"${cell}"`).join("\t"))
      .join("\n");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tableToText(table));
    setShowCopied(true);
    if (user) {
      posthog.capture("copy_file_table", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
    setTimeout(() => setShowCopied(false), 2000);
  };

  if (showCopied) {
    return (
      <div className="flex flex-row items-center space-x-2 hover:cursor-pointer">
        <CheckIcon className="h-4 w-4 text-gray-500" />
        <p className="text-xs text-gray-500">Copied!</p>
      </div>
    );
  }

  return (
    <div
      className="flex flex-row items-center space-x-2 hover:cursor-pointer"
      onClick={copyToClipboard}
    >
      <ClipboardIcon className="h-4 w-4 text-gray-500" />
      <p className="text-xs text-gray-500">Copy table</p>
    </div>
  );
}

function TableRender({ table }: { table: Table }) {
  const array = [];
  for (let i = 0; i < table.row_count; i++) {
    const row = [];
    for (let j = 0; j < table.column_count; j++) {
      row.push("");
    }
    array.push(row);
  }

  for (const cell of table.cells) {
    array[cell.row_index][cell.column_index] = cell.content;
  }

  return (
    <div className="outline outline-1 outline-gray-100 rounded-md overflow-auto">
      <table className="w-full">
        <tbody>
          {array.map((row, i) => (
            <tr key={i} className={i % 2 ? "bg-white" : "bg-gray-50"}>
              {row.map((cell, j) => (
                <td key={j} className="px-2">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function InfiniteScroll({ loadMore }: { loadMore: () => void }) {
  const listEndRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMore();
      }
    });

    if (listEndRef.current) {
      observer.observe(listEndRef.current);
    }

    return () => {
      if (listEndRef.current) {
        observer.unobserve(listEndRef.current);
      }
    };
  }, [loadMore]);

  return <div id="listEnd" ref={listEndRef} />;
}

interface TablesProps {
  orgId: string;
  dealId: string;
  fileId: string;
  fileTables: FileTableRead[];
  setFileViewerParams: (params: FileViewerParams) => void;
  onScroll: () => void;
}
function Tables({
  orgId,
  dealId,
  fileId,
  fileTables,
  setFileViewerParams,
  onScroll,
}: TablesProps) {
  const [selectedTables, setSelectedTables] = useState<Set<number>>(new Set());

  const toggleAllTables = () => {
    if (selectedTables.size > fileTables.length / 2) {
      setSelectedTables(new Set());
    } else {
      setSelectedTables(
        new Set(fileTables.map((fileTable) => fileTable.number)),
      );
    }
  };

  const toggleTable = (tableNumber: number) => {
    if (selectedTables.has(tableNumber)) {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.delete(tableNumber);
      setSelectedTables(newSelectedTables);
    } else {
      const newSelectedTables = new Set(selectedTables);
      newSelectedTables.add(tableNumber);
      setSelectedTables(newSelectedTables);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b-[1px] border-gray-200 pb-2 mb-2 ml-6 items-center">
        <div className="ml-1 mr-3">
          <CheckBox
            state={
              selectedTables.size === fileTables.length
                ? "checked"
                : selectedTables.size === 0
                  ? "unchecked"
                  : "indeterminate"
            }
            onClick={toggleAllTables}
          />
        </div>
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">
          Tables
        </h1>
        <CreateFileTablesExportButton
          orgId={orgId}
          dealId={dealId}
          fileId={fileId}
          tableNumbers={Array.from(selectedTables)}
          all={selectedTables.size === fileTables.length}
        />
      </div>
      <div className="flex-grow overflow-auto prose-sm">
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200">
          {fileTables.map((fileTable) => (
            <li key={fileTable.id} className="py-4">
              <div className="flex flex-row items-center pb-2">
                <div className="mr-3">
                  <CheckBox
                    state={
                      selectedTables.has(fileTable.number)
                        ? "checked"
                        : "unchecked"
                    }
                    onClick={() => toggleTable(fileTable.number)}
                  />
                </div>

                {fileTable.page_number !== null && (
                  <p
                    className="text-sm font-semibold hover:cursor-pointer text-blue-500"
                    onClick={() => {
                      setFileViewerParams({
                        fileId: fileId,
                        pageNumber: fileTable.page_number,
                        websiteId: null,
                        webpageId: null,
                        waiting: true,
                      });
                    }}
                  >
                    Table {fileTable.number + 1}, Page {fileTable.page_number}
                  </p>
                )}
                {fileTable.page_number === null && (
                  <p className="text-sm font-semibold text-gray-900">
                    Table {fileTable.number + 1}
                    {fileTable.page_number}
                  </p>
                )}
                <div className="ml-auto">
                  <CopyToClipboard table={fileTable.table} />
                </div>
              </div>
              <TableRender table={fileTable.table} />
            </li>
          ))}
          <InfiniteScroll loadMore={onScroll} />
        </ul>
      </div>
    </div>
  );
}

function _File({
  orgId,
  dealId,
  fileId,
}: {
  orgId: string;
  dealId: string;
  fileId: string;
}) {
  const fileQuery = useDealFileQuery(orgId, dealId, fileId);
  const fileTablesQuery = useDealFileTablesQuery(orgId, dealId, fileId);
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: fileId,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  if (fileQuery.isLoading || fileTablesQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">Loading file tables...</p>
        </ul>
      </div>
    );
  }

  if (
    (fileQuery.error instanceof APIError &&
      fileQuery.error.type === "PermissionError") ||
    (fileTablesQuery.error instanceof APIError &&
      fileTablesQuery.error.type === "PermissionError")
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">
            {"You don't have permission to view this file."}
          </p>
        </ul>
      </div>
    );
  }

  if (fileQuery.isError || fileTablesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="pb-4 text-sm text-gray-500">
            Error listing file tables.
          </p>
        </ul>
      </div>
    );
  }
  return (
    <div className="flex flex-row h-[calc(100vh-9.5rem)] sm:h-[calc(100vh-5.5rem)]">
      <div className="w-1/2 h-full">
        <FileViewer
          orgId={orgId}
          dealId={dealId}
          {...fileViewerParams}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <div className="w-1/2 h-full">
        <Tables
          orgId={orgId}
          dealId={dealId}
          fileId={fileId}
          fileTables={fileTablesQuery.data.pages.flatMap((page) => page.data)}
          setFileViewerParams={setFileViewerParams}
          onScroll={() => {
            if (fileTablesQuery.hasNextPage) {
              fileTablesQuery.fetchNextPage();
            }
          }}
        />
      </div>
    </div>
  );
}

export default function File() {
  const { orgId, dealId, fileId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const fileQuery = useDealFileQuery(orgId, dealId, fileId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Files", href: `/orgs/${orgId}/deals/${dealId}/files` },
        { name: fileQuery.data?.name || "...", href: null },
      ]}
    >
      <div className="p-4">
        <_File orgId={orgId} dealId={dealId} fileId={fileId} />
      </div>
    </TabLayout>
  );
}
