import OpenAI from "openai";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CitationInstance } from "../../../messageUtils";
import { FileViewerParams } from "../../FileViewer";
import { IndividualResponseSizeState } from "../../ResponseSizeToggle";

function Source({
  citationInstance,
  setFileViewerParams,
  i,
}: {
  citationInstance: CitationInstance;
  setFileViewerParams: (params: FileViewerParams) => void;
  i: number;
}) {
  const searchResult = citationInstance.searchResult;
  if ("file_id" in searchResult) {
    return (
      <li
        key={i}
        onClick={() =>
          setFileViewerParams({
            fileId: searchResult.file_id,
            pageNumber: searchResult.page_number,
            websiteId: null,
            webpageId: null,
            waiting: true,
          })
        }
      >
        <a className="hover:cursor-pointer text-blue-500">
          {citationInstance.citation_number}. {searchResult.file_name}, page{" "}
          {searchResult.page_number}
        </a>
      </li>
    );
  } else {
    return (
      <li
        key={i}
        onClick={() =>
          setFileViewerParams({
            fileId: null,
            pageNumber: null,
            websiteId: searchResult.website_id,
            webpageId: searchResult.webpage_id,
            waiting: false,
          })
        }
      >
        <a className="hover:cursor-pointer text-blue-500">
          {citationInstance.citation_number}. {searchResult.url}
        </a>
      </li>
    );
  }
}

export default function DealQuestionThreadMarkdown({
  content,
  shortContent,
  citationInstances,
  responseSizeState,
  setFileViewerParams,
}: {
  orgId: string;
  dealId: string;
  messages: OpenAI.Chat.ChatCompletionMessageParam[];
  index: number;
  content: string;
  shortContent: string;
  citationInstances: CitationInstance[];
  responseSizeState: IndividualResponseSizeState;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  if (responseSizeState === "collapsed") {
    return <></>;
  }

  if (responseSizeState === "shortened") {
    content = shortContent;
  }

  return (
    <div className="prose prose-sm max-w-screen-lg">
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ ...props }) => {
            return (
              <a
                className="hover:cursor-pointer text-blue-500"
                onClick={() => {
                  const info = props.href?.split(",");
                  if (info && info.length === 3 && info[0] === "file") {
                    setFileViewerParams({
                      fileId: info[1],
                      pageNumber: parseInt(info[2]),
                      websiteId: null,
                      webpageId: null,
                      waiting: true,
                    });
                  }
                  if (info && info.length === 3 && info[0] === "webpage") {
                    setFileViewerParams({
                      fileId: null,
                      pageNumber: null,
                      websiteId: info[1],
                      webpageId: info[2],
                      waiting: false,
                    });
                  }
                }}
              >
                [{props.children}]
              </a>
            );
          },
        }}
      >
        {content ? content : "Question could not be answered."}
      </Markdown>
      <div className="flex flex-row">
        {citationInstances.length > 0 && (
          <div>
            <p>Sources:</p>
            <ul>
              {citationInstances.map((citationInstance, i) => (
                <Source
                  key={i}
                  citationInstance={citationInstance}
                  setFileViewerParams={setFileViewerParams}
                  i={i}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
