import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface PermissionRead {
  permission: string;
}

export interface RoleRead {
  role: string;
  permissions: PermissionRead[];
}

export const listUserOrgRoles = async (
  authUser: User,
  orgId: string,
): Promise<RoleRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/users/current/orgs/${orgId}/roles`, {
      headers: {
        Authorization: `Bearer ${await authUser.getIdToken()}`,
      },
    }),
  );
};
