import { assertUserIsAuthenticated } from "../utils";
import { useAuth, defaultRetryFunc } from "./shared";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  listCompanyProfiles,
  createCompanyProfile,
  deleteCompanyProfile,
  readCompanyProfile,
} from "../api/companyProfiles";
import {
  createCompanyProfileExport,
  CompanyProfileExportCreate,
} from "../api/companyProfiles";

export const useCompanyProfilesQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "company-profiles"],
    queryFn: () => listCompanyProfiles(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

interface CompanyProfileCreateWithTemplate {
  name: string;
  companyProfileTemplateId: string;
}

export const useCreateCompanyProfileWithTemplateMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: async (data: CompanyProfileCreateWithTemplate) => {
      return createCompanyProfile(user, orgId, dealId, {
        name: data.name,
        company_profile_template_id: data.companyProfileTemplateId,
      });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "company-profiles"],
      });
    },
  });
};

export const useDeleteCompanyProfileMutation = (
  orgId: string,
  dealId: string,
  companyProfileId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: () =>
      deleteCompanyProfile(user, orgId, dealId, companyProfileId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "company-profiles"],
      });
    },
  });
};

export const useCompanyProfileQuery = (
  orgId: string,
  dealId: string,
  companyProfileId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "company-profiles", companyProfileId],
    queryFn: () => readCompanyProfile(user, orgId, dealId, companyProfileId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.response_status === "pending") {
        return 1000;
      }
      return false;
    },
  });
};

export const useCreateCompanyProfileExportMutation = (
  orgId: string,
  dealId: string,
  companyProfileId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: CompanyProfileExportCreate) =>
      createCompanyProfileExport(user, orgId, dealId, companyProfileId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "company-profiles",
          companyProfileId,
          "exports",
        ],
      });
    },
  });
};
