import { APIError } from "../../../api/shared";
import { useDealQuestionThreadsQuery } from "../../../hooks";
import { useDealQuery } from "../../../hooks/deals";
import { Link, useParams } from "react-router-dom";
import DeleteDealQuestionThreadButton from "./DeleteQuestionThreadButton";
import TabLayout from "../../TabLayout";
import CreateDealQuestionThreadButton from "../CreateDealQuestionThreadButton";
import DealAppToolbar from "../../DealAppToolbar";
import Table from "../../Table";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

type URLParams = {
  orgId: string;
  dealId: string;
};

function _DealQuestionThreadList({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const dealQuestionThreadsQuery = useDealQuestionThreadsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    dealQuestionThreadsQuery.error instanceof APIError &&
    dealQuestionThreadsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list question threads."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (dealQuestionThreadsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing question threads.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!dealQuestionThreadsQuery.isLoading)
    data = dealQuestionThreadsQuery.data.map((thread) => {
      return [
        <Link
          key={`link-${thread.id}`}
          to={`/orgs/${orgId}/deals/${dealId}/question-threads/${thread.id}`}
          className="text-indigo-600"
        >
          {thread.name}
        </Link>,
        <time key={`time-${thread.id}`} dateTime={thread.created_at}>
          {new Date(thread.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions-${thread.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteDealQuestionThreadButton
            orgId={orgId}
            dealId={dealId}
            dealQuestionThreadId={thread.id}
          />
        </div>,
      ];
    });

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={QuestionMarkCircleIcon}
      noDataMessage={"You don't have any question threads yet."}
      noDataButton={
        <CreateDealQuestionThreadButton orgId={orgId} dealId={dealId} />
      }
      isLoading={dealQuestionThreadsQuery.isLoading}
    />
  );
}

export default function DealQuestionThreadList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Question Threads",
          href: `/orgs/${orgId}/deals/${dealId}/question-threads`,
        },
      ]}
      buttons={[
        <CreateDealQuestionThreadButton
          key={orgId}
          orgId={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Question Threads", active: true }]}
        />
        <_DealQuestionThreadList orgId={orgId} dealId={dealId} />
      </div>
    </TabLayout>
  );
}
