import { useParams } from "react-router-dom";
import { useWebsiteQuery } from "../../../hooks/websites";
import TabLayout from "../../TabLayout";
import { useDealQuery } from "../../../hooks/deals";
import DealAppToolbar from "../../DealAppToolbar";
import { Button } from "@capsa-ai/capsa-ui";

type URLParams = {
  orgId: string;
  dealId: string;
  websiteId: string;
};

function downloadFromURL(url: string) {
  if (process.env.NODE_ENV !== "production") {
    url = url.replace("http://storage", "http://localhost");
  }
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.click();
}

export default function Website() {
  const { orgId, dealId, websiteId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const websiteQuery = useWebsiteQuery(orgId, dealId, websiteId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Websites", href: `/orgs/${orgId}/deals/${dealId}/websites` },
        {
          name: websiteQuery.data?.url || "...",
          href: null,
        },
      ]}
      buttons={[]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[
            {
              name: "Website",
              active: true,
            },
          ]}
        />
        {websiteQuery.isLoading && <div>Loading...</div>}
        {websiteQuery.isError && <div>Error loading website.</div>}
        {websiteQuery.isSuccess && (
          <div className="space-y-2 mt-2">
            <div>
              <h1 className="text-sm font-semibold">URL:</h1>
              <p className="text-sm">{websiteQuery.data.url}</p>
            </div>
            <div>
              <h1 className="text-sm font-semibold">
                Number of Items Ingested:
              </h1>
              <p className="text-sm">{websiteQuery.data.items_ingested}</p>
            </div>
            <div>
              <h1 className="text-sm font-semibold">Status:</h1>
              <p className="text-sm">{websiteQuery.data.ingestion_status}</p>
            </div>
            {websiteQuery.data.download_url && (
              <div>
                <Button
                  onClick={() => {
                    if (websiteQuery.data.download_url) {
                      downloadFromURL(websiteQuery.data.download_url);
                    }
                  }}
                >
                  <p>Download</p>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </TabLayout>
  );
}
