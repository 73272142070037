import React, { useEffect } from "react";
import { useSharepointFolders } from "@/features/sharepoint/hooks/folders";
import { FileManager } from "@capsa-ai/capsa-ui";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";

export interface SelectedItems {
  folderIds: string[];
  fileIds: string[];
}

const SharepointBrowser = () => {
  const {
    selectedIds,
    expandedIds,
    setSelectedIds,
    setExpandedIds,
    setFileIds,
    setFolderIds,
  } = useSharepointStore();
  const { files, folders, queriesState } = useSharepointFolders(expandedIds);

  useEffect(() => {
    setFileIds(files.map((f) => f.id));
    setFolderIds(folders.map((f) => f.id));
  }, [files, folders]);

  return (
    <div className="border rounded-layout overflow-auto flex-1">
      <FileManager
        list={[...folders, ...files]}
        selectedIds={selectedIds}
        expandedIds={expandedIds}
        onSelectionChange={setSelectedIds}
        onExpandedChange={setExpandedIds}
        queriesState={queriesState}
      />
    </div>
  );
};

export default SharepointBrowser;
