import React from "react";
import DealsCreate from "@/components/DealsTab/DealsCreate";
import { CreateCompany } from "@/features/companies/pages/CreateCompany";
import { usePostHog } from "posthog-js/react";

const CreateCompanyFeatureFlag = () => {
  const posthog = usePostHog();

  if (posthog.isFeatureEnabled("new_create_deal")) {
    return <CreateCompany />;
  }
  return <DealsCreate />;
};

export default CreateCompanyFeatureFlag;
