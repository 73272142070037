import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Role {
  role: string;
}

export interface DealUserRead {
  id: string;
  auth_identity_id: string;
  email: string;
  roles: Role[];
}

export const listDealUsers = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DealUserRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/users`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface DealUserCreate {
  user_id: string;
  roles: string[];
}

export const createDealUser = async (
  user: User,
  orgId: string,
  dealId: string,
  data: DealUserCreate,
): Promise<DealUserRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const readDealUser = async (
  user: User,
  orgId: string,
  dealId: string,
  userId: string,
): Promise<DealUserRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface DealUserUpdate {
  roles: string[];
}

export const updateDealUser = async (
  user: User,
  orgId: string,
  dealId: string,
  userId: string,
  data: DealUserUpdate,
): Promise<DealUserRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/users/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const deleteDealUser = async (
  user: User,
  orgId: string,
  dealId: string,
  userId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/users/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};
