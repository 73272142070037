import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Cell {
  kind: "content" | "rowHeader" | "columnHeader" | "stubHead" | "description";
  row_index: number;
  column_index: number;
  content: string;
}

// Interface for Table
export interface Table {
  row_count: number;
  column_count: number;
  cells: Cell[];
}

// Interface for FileTable
export interface FileTable {
  id: string;
  page_number: number | null;
  number: number;
  table: Table;
  created_at: string; // Assuming ISO 8601 string format for datetime
}

// Interface for File
export interface File {
  id: string;
  name: string;
  tables: FileTable[];
}

// Interface for TablePointer
export interface TablePointer {
  file_id: string;
  file_index: number;
  table_id: string;
  table_index: number;
}

// Interface for TableAggregationColumn
export interface TableAggregationColumn {
  tables: TablePointer[];
}

// Interface for TableAggregationGroup
export interface TableAggregationGroup {
  columns: TableAggregationColumn[];
}

// Interface for TableAggregationLayout
export interface TableAggregationLayout {
  org_id: string;
  deal_id: string;
  files: File[];
  layout: TableAggregationGroup[];
}

export interface TableAggregationLayoutCreate {
  file_ids: string[];
}

export const createTableAggregationLayout = async (
  user: User,
  orgId: string,
  dealId: string,
  data: TableAggregationLayoutCreate,
): Promise<TableAggregationLayout> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregation-layouts`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};
