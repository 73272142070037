import React, { useState, createContext, useContext } from "react";

export interface Upload {
  loadedBytes: number;
  totalBytes: number;
}

interface UploadContextType {
  uploads: Map<string, Upload>;
  updateUpload: (key: string, value: Upload) => void;
  removeUpload: (key: string) => void;
}

// Step 2: Create the Context with a default value
const UploadContext = createContext<UploadContextType>({
  uploads: new Map(),
  updateUpload: () => {},
  removeUpload: () => {},
});

interface Props {
  children: React.ReactNode;
}

// Step 3: Create the Provider Component
const UploadProvider: React.FC<Props> = ({ children }) => {
  const [uploads, setUploads] = useState(new Map());

  const updateUpload = (key: string, value: Upload) => {
    setUploads((prevUploads) => new Map(prevUploads.set(key, value)));
  };

  const removeUpload = (key: string) => {
    setUploads((prevUploads) => {
      const newUploads = new Map(prevUploads);
      newUploads.delete(key);
      return newUploads;
    });
  };

  return (
    <UploadContext.Provider value={{ uploads, updateUpload, removeUpload }}>
      {children}
    </UploadContext.Provider>
  );
};

// Step 4: Create a custom hook to consume the context easily
const useUpload = () => useContext(UploadContext);

export { UploadProvider, useUpload };
