import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";
import OpenAI from "openai";

export interface DealQuestionThreadList {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  response_status: "pending" | "complete" | "failed";
  created_at: string;
}

export const listDealQuestionThreads = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DealQuestionThreadList[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/question-threads`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface Question {
  content: string;
}

export interface ShortAnswer {
  content: string;
}

export interface QuestionAndMessages {
  question: Question;
  messages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
  short_answer: ShortAnswer | null;
}

export interface QuestionThread {
  questions_and_messages: QuestionAndMessages[];
}

export interface DealQuestionThreadRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  thread: QuestionThread;
  response_status: "pending" | "completed" | "failed";
  created_at: string;
}

export const readDealQuestionThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string,
): Promise<DealQuestionThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/question-threads/${dealQuestionThreadId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface DealQuestionThreadCreate {
  name: string;
  thread: QuestionThread;
}

export const createDealQuestionThread = async (
  user: User,
  orgId: string,
  dealId: string,
  data: DealQuestionThreadCreate,
): Promise<DealQuestionThreadRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/question-threads`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export interface DealQuestionThreadUpdate {
  name: string | null;
  thread: QuestionThread | null;
}

export const updateDealQuestionThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string,
  data: DealQuestionThreadUpdate,
): Promise<DealQuestionThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/question-threads/${dealQuestionThreadId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};

export const deleteDealQuestionThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealQuestionThreadId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/question-threads/${dealQuestionThreadId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
