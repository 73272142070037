import { TrashIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { DealRead } from "../../api/deals";
import { useDeleteDealMutation } from "../../hooks/deals";
import DealDeletionModal from "../DealDeletionModal";

interface Props {
  orgId: string;
  deal: DealRead;
}

export default function DeleteDealButton({ orgId, deal }: Props) {
  const deleteDealMutation = useDeleteDealMutation(orgId);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <div>
      <DealDeletionModal
        headingText="Delete Deal"
        paragraphText={`Are you sure you want to delete the ${deal.name} deal?`}
        confirmationButtonText="Delete"
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        mutation={deleteDealMutation}
        mutationData={{ dealId: deal.id }}
        onSuccess={() => setOpenDeleteModal(false)}
        orgId={orgId}
        dealId={deal.id}
      />
      <button
        type="button"
        onClick={() => setOpenDeleteModal(true)}
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
      </button>
    </div>
  );
}
