import OpenAI from "openai";
import { ShortAnswer } from "./api/dealQuestionThreads";

export interface FileSearchResult {
  id: string;
  node_id: string;
  file_id: string;
  file_name: string;
  page_number: number;
  content: string;
}

export interface WebpageSearchResult {
  id: string;
  node_id: string;
  website_id: string;
  webpage_id: string;
  url: string;
  retrieved_at: string;
  content: string;
}

export type SearchResult = FileSearchResult | WebpageSearchResult;

export interface SearchDealFilesResponse {
  function_name: "search_deal_files";
  search_results: SearchResult[];
}

type ToolResponse = SearchDealFilesResponse;

export interface CitationInstance {
  citation_number: number;
  searchResult: SearchResult;
}

const regexp = /【([a-zA-Z0-9]{5})】/g;

export function getMessagesAndCitationInstances(
  messages: OpenAI.Chat.ChatCompletionMessageParam[],
  shortAnswer: ShortAnswer | null,
) {
  const idToSearchResult = new Map<string, SearchResult>();
  messages.forEach((message) => {
    if (message.role === "tool") {
      const toolResponse = JSON.parse(message.content) as ToolResponse;
      if (toolResponse.function_name === "search_deal_files") {
        toolResponse.search_results.forEach((searchResult) => {
          idToSearchResult.set(searchResult.id, searchResult);
        });
      }
    }
  });

  const idToIndex = new Map();
  const messagesAndCitationInstances = messages.map((message) => {
    message = { ...message };
    const cited = new Set();
    const messageCitationInstances: CitationInstance[] = [];
    if (message.role === "assistant" && message.content) {
      [...message.content.matchAll(regexp)].forEach((match) => {
        const id = match[1];
        // If the id is not in the search results, ignore it
        if (idToSearchResult.get(id) === undefined) {
          return;
        }
        if (!idToIndex.has(id)) {
          idToIndex.set(id, idToIndex.size);
        }
        if (!cited.has(id)) {
          const searchResult = idToSearchResult.get(id);
          if (searchResult !== undefined) {
            messageCitationInstances.push({
              citation_number: idToIndex.get(id),
              searchResult: searchResult,
            });
            cited.add(id);
          }
        }
      });
      message.content = message.content.replaceAll(regexp, (a, b) => {
        const index = idToIndex.get(b);
        const searchResult = idToSearchResult.get(b);
        if (searchResult === undefined) {
          return "";
        }
        if ("webpage_id" in searchResult) {
          return `[${index}](webpage,${searchResult.website_id},${searchResult.webpage_id})`;
        }
        return `[${index}](file,${searchResult.file_id},${searchResult.page_number})`;
      });
    }
    return {
      message: message,
      citationInstances: [...messageCitationInstances],
    };
  });
  if (shortAnswer !== null) {
    shortAnswer = { ...shortAnswer };
    shortAnswer.content = shortAnswer.content.replaceAll(regexp, (a, b) => {
      const index = idToIndex.get(b);
      const searchResult = idToSearchResult.get(b);
      if (searchResult === undefined) {
        return "";
      }
      if ("webpage_id" in searchResult) {
        return `[${index}](webpage,${searchResult.website_id},${searchResult.webpage_id})`;
      }
      return `[${index}](file,${searchResult.file_id},${searchResult.page_number})`;
    });
  }
  return { messagesAndCitationInstances, shortAnswer };
}
