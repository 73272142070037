import { useParams } from "react-router-dom";
import CreateQuestionTemplateButton from "./CreateQuestionTemplateButton";
import { QuestionTemplatesList } from "./QuestionTemplatesList";
import TabLayout from "../TabLayout";

type URLParams = {
  orgId: string;
};

export default function QuestionTemplatesTab() {
  const { orgId } = useParams() as URLParams;

  return (
    <TabLayout
      items={[{ name: "Question Templates", href: null }]}
      buttons={[<CreateQuestionTemplateButton key={orgId} orgId={orgId} />]}
    >
      <div className="p-4">
        <div className="flex">
          <p className="text-sm max-w-2xl text-gray-700">
            Question templates contain a set of questions that you can apply to
            deals.
          </p>
        </div>
        <QuestionTemplatesList orgId={orgId} />
      </div>
    </TabLayout>
  );
}
