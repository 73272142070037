import { IconButton } from "@capsa-ai/capsa-ui";
import { useReactFlow } from "@xyflow/react";

export const CustomControls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  return (
    <div className="react-flow__panel horizontal bottom flex right gap-1">
      <IconButton
        size="lg"
        variant="secondary"
        onClick={() => zoomIn()}
        icon="zoomIn"
      />
      <IconButton
        size="lg"
        variant="secondary"
        onClick={() => zoomOut()}
        icon="zoomOut"
      />
      <IconButton
        size="lg"
        variant="secondary"
        onClick={() => fitView()}
        icon="fit"
      />
    </div>
  );
};
