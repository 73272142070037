import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDealQuestionThreadQuery } from "../../../hooks";
import { useDealQuery } from "../../../hooks/deals";
import FileViewer, { FileViewerParams } from "../../FileViewer";
import TabLayout from "../../TabLayout";
import CreateDealQuestionThreadExportButton from "../CreateDealQuestionThreadExportButton";
import UpdateDealQuestionThreadButton from "../UpdateDealQuestionThreadButton";
import DealQuestionThreadWindow from "./DealQuestionThreadWindow";

type URLParams = {
  orgId: string;
  dealId: string;
  dealQuestionThreadId: string;
};

export default function DealQuestionThread() {
  const { orgId, dealId, dealQuestionThreadId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const questionThreadQuery = useDealQuestionThreadQuery(
    orgId,
    dealId,
    dealQuestionThreadId,
  );
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Question Threads",
          href: `/orgs/${orgId}/deals/${dealId}/question-threads`,
        },
        { name: questionThreadQuery.data?.name || "...", href: null },
      ]}
      buttons={[
        <UpdateDealQuestionThreadButton
          key="update-deal-question-thread-button"
          orgId={orgId}
          dealId={dealId}
          dealQuestionThreadId={dealQuestionThreadId}
        />,
        <CreateDealQuestionThreadExportButton
          key="create-deal-question-thread-export-button"
          orgId={orgId}
          dealId={dealId}
          dealQuestionThreadId={dealQuestionThreadId}
        />,
      ]}
    >
      <div className="flex flex-row p-4 h-[calc(100vh-7.5rem)] sm:h-[calc(100vh-3.5rem)]">
        <div className="w-1/2 h-full mr-2">
          <FileViewer
            orgId={orgId}
            dealId={dealId}
            {...fileViewerParams}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
        <div className="w-1/2 h-full ml-2">
          <DealQuestionThreadWindow
            orgId={orgId}
            dealId={dealId}
            dealQuestionThreadId={dealQuestionThreadId}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
      </div>
    </TabLayout>
  );
}
