import { APIError } from "../../../api/shared";
import { useParams } from "react-router-dom";
import { useDocumentUploadJobsQuery } from "../../../hooks/documentUploadJobs";
import DeleteDocumentUploadJobButton from "./DeleteDocumentUploadJobButton";
import { DocumentMagnifyingGlassIcon } from "@heroicons/react/24/outline";
import CreateRegistrySearchJobButton from "../CreateRegistrySearchJobButton";
import Table from "../../Table";
import TabLayout from "../../TabLayout";
import { useDealQuery } from "../../../hooks/deals";
import DealAppToolbar from "../../DealAppToolbar";

type URLParams = {
  orgId: string;
  dealId: string;
};

function PeriodSpan({
  fyStart,
  fyEnd,
  className,
}: {
  fyStart: number | null;
  fyEnd: number | null;
  className?: string;
}) {
  if (fyStart && fyEnd) {
    return (
      <span className={className}>
        {" - "}
        {fyStart} - {fyEnd}
      </span>
    );
  }
  if (fyStart) {
    return (
      <span className={className}>
        {" - "}Since {fyStart}
      </span>
    );
  }
  if (fyEnd) {
    return (
      <span className={className}>
        {" - "}Until {fyEnd}
      </span>
    );
  }
  return <span />;
}

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _RegistrySearchJobList({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const jobsQuery = useDocumentUploadJobsQuery(orgId, dealId);

  // Insufficient permissions
  if (
    jobsQuery.error instanceof APIError &&
    jobsQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list registry searches."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (jobsQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing registry searches.
          </p>
        </ul>
      </div>
    );
  }
  let data: React.ReactElement[][] = [];
  if (!jobsQuery.isLoading) {
    const registrySearchJobs = jobsQuery.data;

    data = registrySearchJobs.map((job) => {
      const ret = [
        // Name and period
        <p key={`name-${job.id}`} className="truncate text-sm">
          {job.data.company_name}
          <PeriodSpan
            fyStart={null}
            fyEnd={null}
            className="text-sm text-gray-500"
          />
        </p>,
        // Documents uploaded
        <p key={`documents-${job.id}`}>{job.documents_uploaded}</p>,
        // Created at
        <time key={`created-${job.id}`} dateTime={job.created_at}>
          {new Date(job.created_at).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </time>,
        // Status
        <StatusTag key={`status-${job.id}`} status={job.status} />,
        <div
          key={`delete-document-upload-job-${job.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteDocumentUploadJobButton
            orgId={orgId}
            dealId={dealId}
            documentUploadJobId={job.id}
          />
        </div>,
      ];
      return ret;
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Documents", "Created", "Status", ""]}
      data={data}
      noDataIcon={DocumentMagnifyingGlassIcon}
      noDataMessage="You don't have any registry searches yet."
      noDataButton={
        <CreateRegistrySearchJobButton orgId={orgId} dealId={dealId} />
      }
      isLoading={jobsQuery.isLoading}
    />
  );
}

export default function RegistrySearchJobList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Registry Searches", href: null },
      ]}
      buttons={[
        <CreateRegistrySearchJobButton
          key={orgId}
          orgId={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Registry Searches", active: true }]}
        />
        <_RegistrySearchJobList orgId={orgId} dealId={dealId} />
      </div>
    </TabLayout>
  );
}
