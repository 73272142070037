import { TrashIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { OrgUserRead } from "../../../api/users";
import {
  useDeleteOrgUserMutation,
  useUserOrgPermissionsQuery,
} from "../../../hooks";
import { hasPermission } from "../../../utils";
import DeletionModal from "../../DeletionModal";
import Spinner from "../../Spinner";

interface Props {
  orgId: string;
  user: OrgUserRead;
}

export default function DeleteOrgUserButton({ orgId, user }: Props) {
  const permissionsQuery = useUserOrgPermissionsQuery(orgId);
  const deleteOrgUserMutation = useDeleteOrgUserMutation(orgId);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  switch (hasPermission(permissionsQuery, "users.delete")) {
    case "loading":
      return (
        <div>
          <button
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled
          >
            <Spinner h="4" w="h" />
          </button>
        </div>
      );
    case "true":
      return (
        <div>
          <DeletionModal
            headingText="Remove User"
            paragraphText={`Are you sure you want to remove ${user.email} from the org?`}
            confirmationButtonText="Remove"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            mutation={deleteOrgUserMutation}
            mutationData={{ userId: user.id }}
            onSuccess={() => setOpenDeleteModal(false)}
          />
          <button
            type="button"
            onClick={() => setOpenDeleteModal(true)}
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          >
            <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
          </button>
        </div>
      );
    case "false":
      return (
        <div>
          <button
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled
            title="Insufficient permissions, contact org admin."
          >
            <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
          </button>
        </div>
      );
    default:
      return (
        <div>
          <button
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
            disabled
          >
            <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
          </button>
        </div>
      );
  }
}
