import { Link } from "react-router-dom";
import { APIError } from "../../api/shared";
import { useQuestionTemplatesQuery } from "../../hooks/questionTemplates";
import DeleteQuestionTemplateButton from "./DeleteQuestionTemplateButton";
import UpdateQuestionTemplateButton from "./UpdateQuestionTemplateButton";
import Table from "../Table";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import CreateQuestionTemplateButton from "./CreateQuestionTemplateButton";

interface Props {
  orgId: string;
}

export function QuestionTemplatesList({ orgId }: Props) {
  const questionTemplatesQuery = useQuestionTemplatesQuery(orgId);

  // Insufficient permissions
  if (
    questionTemplatesQuery.error instanceof APIError &&
    questionTemplatesQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list question templates."}
        </p>
      </ul>
    );
  }

  // Error
  if (questionTemplatesQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          Error listing question templates.
        </p>
      </ul>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!questionTemplatesQuery.isLoading) {
    data = questionTemplatesQuery.data.map((questionTemplate) => {
      return [
        <Link
          key={`link-${questionTemplate.id}`}
          to={`/orgs/${orgId}/question-templates/${questionTemplate.id}`}
          className="text-indigo-600"
        >
          {questionTemplate.name}
        </Link>,
        <time
          key={`time-${questionTemplate.id}`}
          dateTime={questionTemplate.created_at}
        >
          {new Date(questionTemplate.created_at).toLocaleString()}
        </time>,
        <div
          key={`actions-${questionTemplate.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateQuestionTemplateButton
            orgId={orgId}
            questionTemplate={questionTemplate}
          />
          <DeleteQuestionTemplateButton
            orgId={orgId}
            questionTemplate={questionTemplate}
          />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={DocumentDuplicateIcon}
      noDataMessage="You don't have any question templates yet."
      noDataButton={<CreateQuestionTemplateButton orgId={orgId} />}
      isLoading={questionTemplatesQuery.isLoading}
    />
  );
}
