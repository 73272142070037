import { useCreateTableAggregationLayoutQuery } from "../../../../hooks/tableAggregationLayouts";
import { SelectedFile } from "./SelectedFilesPage";
import TableAggregationLayoutBuilder from "./TableAggregationLayoutBuilder";
import { ScaleProvider } from "./TableAggregationLayoutBuilder/utils";

function ArrangeTablesPageInner({
  orgId,
  dealId,
  selectedFiles,
}: {
  orgId: string;
  dealId: string;
  selectedFiles: SelectedFile[];
}) {
  const query = useCreateTableAggregationLayoutQuery(
    orgId,
    dealId,
    selectedFiles.map((file) => file.id),
  );

  if (query.isLoading) {
    return <div>Loading...</div>;
  }

  if (query.isError) {
    return <div>Error predict table aggregation layout.</div>;
  }

  return (
    <TableAggregationLayoutBuilder
      orgId={orgId}
      dealId={dealId}
      files={query.data.files}
      layout={query.data.layout}
    />
  );
}

export default function ArrangeTablesPage(props: {
  orgId: string;
  dealId: string;
  selectedFiles: SelectedFile[];
}) {
  return (
    <ScaleProvider>
      <ArrangeTablesPageInner {...props} />
    </ScaleProvider>
  );
}
