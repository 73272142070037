import { defaultRetryFunc, useAuth } from "../../../hooks/shared";
import { assertUserIsAuthenticated } from "../../../utils";
import { useQueryClient } from "@tanstack/react-query";
import { readSharepointFolder, listSharepointFolders } from "../api/folders";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ExternalFile,
  ExternalFolder,
  ExternalFolderContents,
} from "@/types/files";
import { QueriesState, TreeNode } from "@capsa-ai/capsa-ui";

export const useSharepointFolders = (expandedIds: string[]) => {
  const { orgId } = useParams();
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  const queryClient = useQueryClient();
  const [queryIds, setQueryIds] = useState<string[]>([]);
  const [queriesState, setQueriesState] = useState<QueriesState>({});
  const [files, setFiles] = useState<TreeNode[]>([]);
  const [folders, setFolders] = useState<TreeNode[]>([]);

  const expandedIdsSet = new Set(expandedIds);

  const fetchFolder = async (folderId: string, depth: number) => {
    try {
      const key = [orgId, "sharepointFolders", folderId];
      let folder = await queryClient.getQueryData<ExternalFolderContents>(key);
      if (!folder) {
        folder = await queryClient.fetchQuery(
          key,
          () => readSharepointFolder(user, orgId || "", folderId),
          { retry: defaultRetryFunc },
        );
      }
      setQueryIds((prev) => [...prev, folderId]);
      folder?.folders.forEach((f) => {
        if (expandedIdsSet.has(f.id)) {
          fetchFolder(f.id, 0);
        } else if (depth < 1) {
          fetchFolder(f.id, depth + 1);
        }
      });
      addFolders(folder?.folders || [], folderId);
      addFiles(folder?.files || [], folderId);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const key = [orgId, "sharepointFolders"];
      let root = await queryClient.getQueryData<ExternalFolderContents>(key);
      if (!root) {
        root = await queryClient.fetchQuery(
          key,
          () => listSharepointFolders(user, orgId || ""),
          { retry: defaultRetryFunc },
        );
      }
      setQueryIds(["root"]);
      root?.folders.forEach((folder) => fetchFolder(folder.id, 0));
      addFolders(root?.folders || [], "root");
      addFiles(root?.files || [], "root");
    };
    fetch();
  }, [expandedIds]);

  useEffect(() => {
    const states = queriesState;
    const rootQuery = queryClient.getQueryState([orgId, "sharepointFolders"]);
    if (rootQuery) {
      states["root"] = rootQuery;
    }

    folders.forEach((folder) => {
      const query = queryClient.getQueryState([
        orgId,
        "sharepointFolders",
        folder.id,
      ]);
      if (query) {
        states[folder.id] = query;
      }
    });

    setQueriesState(states);
  }, [queryIds]);

  const addFiles = (files: ExternalFile[], folderId: string) => {
    setFiles((prev) => {
      const existingFileIds = new Set(prev.map((file) => file.id));
      const newFiles = files
        .filter((file) => !existingFileIds.has(file.id))
        .map((file) => ({
          ...file,
          type: "file" as "file" | "folder",
          id: file.id,
          name: file.externalPath.split("/").pop() || file.externalPath,
          filetype: file.externalPath.split(".").pop() || "",
          parentId: folderId,
          children: [],
        }));
      return [...prev, ...newFiles];
    });
  };

  const addFolders = (folders: ExternalFolder[], folderId: string) => {
    setFolders((prev) => {
      const existingFolderIds = new Set(prev.map((folder) => folder.id));
      const newFolders = folders
        .filter((folder) => !existingFolderIds.has(folder.id))
        .map((folder) => ({
          ...folder,
          type: "folder" as "file" | "folder",
          id: folder.id,
          name: folder.name,
          parentId: folderId,
          children: [],
        }));
      return [...prev, ...newFolders];
    });
  };

  return { files, folders, queriesState };
};
