import { checkForPassword, hasPermission } from "../../../utils";
import { useCreateDealFileMutation } from "../../../hooks/deals";
import { useUserDealPermissionsQuery } from "../../../hooks/userDealRoles";
import { useEffect, useState } from "react";

interface UploadFilesPixelProps {
  orgId: string;
  dealId: string;
  files: FileList;
  onUploadComplete: () => void;
}

const UploadFilesPixel = ({
  orgId,
  dealId,
  files,
  onUploadComplete,
}: UploadFilesPixelProps) => {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const mutation = useCreateDealFileMutation(orgId, dealId);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const uploadFiles = async () => {
      if (!files || files.length === 0) {
        return;
      }
      if (permissionsQuery.isLoading || !permissionsQuery.data) {
        return;
      }
      if (uploading) {
        return;
      }

      setUploading(true);
      if (!hasPermission(permissionsQuery, "files.create")) {
        return;
      }

      for (const file of Array.from(files)) {
        try {
          const password = await checkForPassword(file);
          mutation.mutate({
            file: file,
            password: password,
          });
        } catch (error) {
          console.error(`"${file.name}" could not be uploaded.`, error);
        }
      }
      onUploadComplete();
    };

    uploadFiles();
  }, [files, permissionsQuery.isLoading]);

  return <></>;
};

export default UploadFilesPixel;
