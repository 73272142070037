import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import { Tag } from "@capsa-ai/capsa-ui";
import {
  Company,
  CompanyNameSuggestion,
  CompanyUrl,
} from "@/features/companies/types/company";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { combine } from "zustand/middleware";

const initialState = {
  tabIndex: 0,
  dealName: "New Deal",
  companyName: "",
  companySuggestion: null as CompanyNameSuggestion | null,
  legalEntity: null as Company | null,
  registryDocuments: [] as RegistryDocument[],
  websiteOptions: [] as CompanyUrl[],
  website: "",
  aliases: [] as Tag[],
  files: new DataTransfer().files,
  dealId: "",
};

export const useCreateCompanyStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setTabIndex: (tabIndex: number) => set({ tabIndex }),
        nextTab: () =>
          set((state) => ({
            tabIndex: state.tabIndex + 1,
          })),
        previousTab: () => set((state) => ({ tabIndex: state.tabIndex - 1 })),
        setDealName: (dealName: string) => set({ dealName }),
        setCompanyName: (companyName: string) => set({ companyName }),
        setCompanySuggestion: (
          companySuggestion: CompanyNameSuggestion | null,
        ) => set({ companySuggestion }),
        setLegalEntity: (legalEntity: Company | null) => set({ legalEntity }),
        resetLegalEntity: () => set({ legalEntity: null }),
        setRegistryDocuments: (registryDocuments: RegistryDocument[]) =>
          set({ registryDocuments }),
        setWebsiteOptions: (options: CompanyUrl[]) =>
          set({
            websiteOptions: options.reduce((acc, value) => {
              if (
                !acc.some(
                  (item) =>
                    new URL(item.companyWebsite)?.hostname ===
                    new URL(value.companyWebsite)?.hostname,
                )
              ) {
                acc.push(value);
              }
              return acc;
            }, [] as CompanyUrl[]),
          }),
        setWebsite: (website: string) => set({ website }),
        removeAlias: (alias: string) =>
          set((state) => ({
            aliases: state.aliases.filter((tab) => tab.id !== alias),
          })),
        setAliases: (aliases: Tag[]) => set({ aliases }),
        setFiles: (files: FileList) => set({ files }),
        setDealId: (dealId: string) => set({ dealId }),
      })),
    ),
    { name: "CreateCompanyStore" },
  ),
);

const handleEssentialAliases = (id: string, label: string) => {
  const { aliases, setAliases } = useCreateCompanyStore.getState();
  const newAliases = [...aliases];

  const existingIndex = newAliases.findIndex((alias) => alias.id === id);
  if (existingIndex !== -1) {
    if (label === "") {
      newAliases.splice(existingIndex, 1);
    } else {
      newAliases[existingIndex].label = label;
    }
  } else {
    newAliases.push({ id, label, preventRemoval: true });
  }
  setAliases(newAliases);
};

useCreateCompanyStore.subscribe(
  (state) => state.dealName,
  (dealName) => {
    handleEssentialAliases("deal-name", dealName);
  },
);

useCreateCompanyStore.subscribe(
  (state) => state.companyName,
  (companyName) => {
    handleEssentialAliases("company-name", companyName);
  },
);

useCreateCompanyStore.subscribe(
  (state) => state.legalEntity,
  (legalEntity) => {
    handleEssentialAliases("legal-entity", legalEntity?.name || "");
  },
);
