import { User } from "../types";
import {
  baseURL,
  resolveFetch,
  resolveFetchPaginated,
  PaginatedResponse,
} from "./shared";

export interface Cell {
  kind: "content" | "rowHeader" | "columnHeader" | "stubHead" | "description";
  row_index: number;
  column_index: number;
  content: string;
}

export interface Table {
  row_count: number;
  column_count: number;
  cells: Cell[];
}
export interface FileTableRead {
  id: string;
  org_id: string;
  deal_id: string;
  file_id: string;
  page_number: number;
  number: number;
  table: Table;
}

export const listDealFilesTables = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
  cursor: number,
): Promise<PaginatedResponse<FileTableRead[]>> => {
  return await resolveFetchPaginated(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}/tables?cursor=${cursor}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export const readDealFileTable = async (
  user: User,
  orgId: string,
  dealId: string,
  fileId: string,
  fileTableId: string,
): Promise<FileTableRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/files/${fileId}/tables/${fileTableId}}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
