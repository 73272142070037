import { createPortal } from "react-dom";
import {
  File,
  TableAggregationColumn,
  TableAggregationGroup,
} from "../../../../../api/tableAggregationLayouts";
import TableAggregationTableRender from "./TableAggregationTableRender";
import {
  classNames,
  removeEmptyColumnsAndGroups,
  useDragItem,
  useDropZone,
  useScale,
} from "./utils";
import { DragItemType, DropZoneType } from "./types";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";

function RowDropZone({
  groupIndex,
  columnIndex,
  rowIndex,
}: {
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
}) {
  const { ref, isDraggedOver } = useDropZone(
    {
      type: DropZoneType.ROW,
      groupIndex,
      columnIndex,
      rowIndex,
    },
    [DragItemType.TABLE, DragItemType.COLUMN],
  );
  return (
    <div className="flex">
      <div className="w-full h-4 flex items-center" ref={ref}>
        {isDraggedOver && <div className="w-full border-t-2 border-black" />}
      </div>
    </div>
  );
}

function deleteColumnItem(
  columnItem: { groupIndex: number; columnIndex: number },
  setLayout: React.Dispatch<React.SetStateAction<TableAggregationGroup[]>>,
) {
  const confirmDelete = window.confirm(
    "Are you sure you want to delete this column?",
  );
  if (!confirmDelete) return;
  setLayout((prevLayout) => {
    let newLayout = JSON.parse(JSON.stringify(prevLayout));
    newLayout[columnItem.groupIndex].columns.splice(columnItem.columnIndex, 1);
    newLayout = removeEmptyColumnsAndGroups(newLayout);
    return newLayout;
  });
}

function TableAggregationColumnRenderInner({
  files,
  column,
  groupIndex,
  columnIndex,
  setLayout,
}: {
  files: File[];
  column: TableAggregationColumn;
  groupIndex: number;
  columnIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  return (
    <div className="flex flex-col bg-gray-100 pt-4 px-4 rounded-md border border-gray-300 relative">
      <div className="absolute left-4 top-2">
        <button
          onClick={() =>
            deleteColumnItem({ groupIndex, columnIndex }, setLayout)
          }
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      </div>
      <RowDropZone
        groupIndex={groupIndex}
        columnIndex={columnIndex}
        rowIndex={0}
      />
      {column.tables.map((table, index) => (
        <>
          <TableAggregationTableRender
            files={files}
            tablePointer={table}
            groupIndex={groupIndex}
            columnIndex={columnIndex}
            rowIndex={index}
            setLayout={setLayout}
          />
          <RowDropZone
            groupIndex={groupIndex}
            columnIndex={columnIndex}
            rowIndex={index + 1}
          />
        </>
      ))}
    </div>
  );
}

function TableAggregationColumnRenderPreview(props: {
  files: File[];
  column: TableAggregationColumn;
  groupIndex: number;
  columnIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { scaleStyle } = useScale();
  return (
    <div className={classNames(scaleStyle, "opacity-40 origin-top-left")}>
      <TableAggregationColumnRenderInner {...props} />
    </div>
  );
}

export default function TableAggregationColumnRender(props: {
  files: File[];
  column: TableAggregationColumn;
  groupIndex: number;
  columnIndex: number;
  setLayout: Dispatch<SetStateAction<TableAggregationGroup[]>>;
}) {
  const { ref, itemState } = useDragItem({
    type: DragItemType.COLUMN,
    groupIndex: props.groupIndex,
    columnIndex: props.columnIndex,
  });
  return (
    <div ref={ref}>
      <div className={itemState.type === "dragging" ? "opacity-60" : ""}>
        <TableAggregationColumnRenderInner {...props} />
      </div>
      {itemState.type === "preview"
        ? createPortal(
            <TableAggregationColumnRenderPreview {...props} />,
            itemState.container,
          )
        : null}
    </div>
  );
}
