import { getLayoutedElements } from "@/features/registrySearch/utils/reactFlow";
import { EntityNode } from "@/features/registrySearch/types/reactFlow";
import {
  Edge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { useEffect, useState } from "react";
import { useRegistrySearchStore } from "@/features/registrySearch/store/useRegistrySearchStore";

const useEntityTree = () => {
  const { entityNodes, entityEdges, legalEntity, setLegalEntity } =
    useRegistrySearchStore();
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { setCenter, getInternalNode } = useReactFlow();

  useEffect(() => {
    const legalEntityAlreadySelected = entityNodes.find(
      (entity) => entity.graphId === legalEntity?.graphId,
    );
    if (!legalEntityAlreadySelected) {
      const ultimateParent = entityNodes.find((node) => node.ultimateParent);
      if (ultimateParent) {
        setLegalEntity(ultimateParent);
      } else {
        setLegalEntity(entityNodes[0]);
      }
    }
  }, [entityNodes]);

  useEffect(() => {
    setTimeout(() => {
      centerNode(legalEntity?.graphId || "");
    }, 100);
  }, [legalEntity]);

  useEffect(() => {
    updateNodes();
  }, [entityNodes, entityEdges, legalEntity?.graphId]);

  const hasChildren = (nodeId: string, edges: Edge[]) => {
    return edges.some((edge) => edge.source === nodeId);
  };

  const hasParent = (nodeId: string, edges: Edge[]) => {
    return edges.some((edge) => edge.target === nodeId);
  };

  const updateNodes = () => {
    setLoading(true);
    const nodes = entityNodes.map((data) => ({
      id: data.graphId,
      type: "customNode",
      position: { x: 0, y: 0 },
      draggable: false,
      data: { ...data, selected: legalEntity?.graphId === data.graphId },
    }));

    const edges: Edge[] = [];
    Object.entries(entityEdges).forEach(([sourceId, targetIds]) => {
      targetIds.forEach((targetId) => {
        edges.push({
          id: `e-${sourceId}-${targetId}`,
          source: sourceId,
          target: targetId,
          type: "customEdge",
          animated: true,
        });
      });
    });

    const layouted = getLayoutedElements(nodes, edges, { direction: "TB" });
    setNodes(
      //eslint-disable-next-line
      [...layouted.nodes].map((node: any) => ({
        ...node,
        data: {
          ...node.data,
          hasChildren: hasChildren(node.id, edges),
          hasParent: hasParent(node.id, edges),
        },
      })) as [],
    );
    setEdges([...layouted.edges] as []);

    setLoading(false);
  };

  const centerNode = (nodeId: string) => {
    const node = getInternalNode(nodeId);
    if (node) {
      const { position, measured } = node as EntityNode;
      const centerX = position.x + (measured.width || 0) / 2;
      const centerY = position.y + (measured.height || 0) / 2;
      setCenter(centerX, centerY, { zoom: 1.75, duration: 800 });
    }
  };

  return {
    nodes,
    edges,
    setNodes,
    setEdges,
    loading,
    onNodesChange,
    onEdgesChange,
    centerNode,
  };
};

export default useEntityTree;
