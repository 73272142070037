import info from "../info";

export default function SingleTenantOrgInvite() {
  return (
    <div className="max-w-xl mx-auto">
      <div className="bg-white rounded-md p-8 shadow-md mt-16">
        <div className="flex flex-col p-8">
          <img src="/logo-1.svg" className="w-64 mx-auto mb-6" />
          <h2 className="mx-auto text-xl mb-6 text-gray-700">{info.orgId}</h2>
          <p className="text-sm max-w-lg text-gray-700 mb-6 text-center">
            Please check your email for a verification link. Click continue once
            verified.
          </p>
          <hr className="mb-6" />
          <button
            onClick={() => window.location.reload()}
            type="button"
            className="mb-6 mx-auto w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Continue
          </button>
          <hr className="mb-6" />
        </div>
      </div>
    </div>
  );
}
