import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  TableAggregationCreate,
  TableAggregationUpdate,
  createTableAggregation,
  deleteTableAggregation,
  listTableAggregations,
  readTableAggregation,
  updateTableAggregation,
} from "../api/tableAggregations";

export const useTableAggregationsQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "table-aggregations"],
    queryFn: () => listTableAggregations(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (
        data &&
        data.some((tableAggregation) => tableAggregation.status === "pending")
      ) {
        return 10000;
      }
      return false;
    },
  });
};

export const useTableAggregationQuery = (
  orgId: string,
  dealId: string,
  tableAggregationId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "table-aggregations",
      tableAggregationId,
    ],
    queryFn: () =>
      readTableAggregation(user, orgId, dealId, tableAggregationId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.status === "pending") {
        return 1000;
      }
      return false;
    },
  });
};

export const useCreateTableAggregationMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: TableAggregationCreate) =>
      createTableAggregation(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "table-aggregations"],
      });
    },
  });
};

export const useUpdateTableAggregationMutation = (
  orgId: string,
  dealId: string,
  tableAggregationId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: TableAggregationUpdate) =>
      updateTableAggregation(user, orgId, dealId, tableAggregationId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "table-aggregations"],
      });
    },
  });
};

export const useDeleteTableAggregationMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ tableAggregationId }: { tableAggregationId: string }) =>
      deleteTableAggregation(user, orgId, dealId, tableAggregationId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "table-aggregations"],
      });
    },
  });
};
