export const createURL = (path: string) => {
  try {
    if (!/^https?:\/\//i.test(path)) {
      path = "https://" + path;
    }
    return new URL(path);
  } catch {
    return null;
  }
};
