import React, { useEffect, useState } from "react";
import { Breadcrumbs } from "@capsa-ai/capsa-ui";
import RegistrySearch from "@/features/registrySearch/pages/RegistrySearch";
import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import CreateRegistryFilesSyncJobButton from "@/features/registrySearch/components/CreateDocumentUploadJobButton";
import { Company } from "@/features/companies/types/company";
import { useRegistrySearchStore } from "../store/useRegistrySearchStore";
import { useDealQuery } from "@/hooks/deals";
import { useNavigate, useParams } from "react-router-dom";

export const DealRegistrySearchView = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<RegistryDocument[]>([]);
  const [company, setCompany] = useState<Company | null>(null);
  const registrySearchStore = useRegistrySearchStore();
  const { dealId, orgId } = useParams();
  const dealQuery = useDealQuery(orgId || "", dealId || "");

  useEffect(() => {
    registrySearchStore.reset();
  }, []);

  return (
    <div className="bg min-h-screen">
      <div className="flex items-center px-4 h-12 border-b gap-1.5">
        <div className="pl-3 w-full">
          <Breadcrumbs
            crumbs={[
              { label: "Deals", to: `/orgs/${orgId}/deals` },
              {
                label: dealQuery.data?.name || "...",
                to: `/orgs/${orgId}/deals/${dealId}`,
              },
              {
                label: "Registry Searches",
                to: `/orgs/${orgId}/deals/${dealId}/registry-search-jobs`,
              },
              { label: "New Registry Search", to: "null" },
            ]}
            onNavigate={navigate}
          />
        </div>
        <CreateRegistryFilesSyncJobButton
          key="delete"
          companyName={company?.name || ""}
          companyIdInRegistry={company?.id || ""}
          files={documents}
          disabled={documents.length === 0}
        />
      </div>
      <div className="flex flex-col px-4 py-6 pb-24 gap-4">
        <RegistrySearch
          onDocumentsChange={setDocuments}
          onLegalEntityChange={setCompany}
        />
      </div>
    </div>
  );
};
