export class User {
  id: string;
  emailVerified: boolean;
  getAccessTokenSilently: () => Promise<string>;
  constructor(
    id: string,
    emailVerified: boolean,
    getAccessTokenSilently: () => Promise<string>,
  ) {
    this.id = id;
    this.emailVerified = emailVerified;
    this.getAccessTokenSilently = getAccessTokenSilently;
  }
  async getIdToken() {
    return this.getAccessTokenSilently();
  }
}
