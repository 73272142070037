import { RegistryDocument } from "@/components/CompanySearch/api/listRegistryDocuments";
import {
  Company,
  CompanyNameSuggestion,
} from "@/features/companies/types/company";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";
import { FilterOptionCheckbox } from "@capsa-ai/capsa-ui";

const initialState = {
  registrySearch: "",
  companySuggestions: [] as CompanyNameSuggestion[],
  selectedCompanySuggestion: null as CompanyNameSuggestion | null,
  entityNodes: [] as Company[],
  entityEdges: {} as { [key: string]: string[] },
  legalEntity: null as Company | null,
  registryDocuments: [] as RegistryDocument[],
  filteredDocuments: [] as RegistryDocument[],
  filterOptions: [] as FilterOptionCheckbox[],
  loading: false,
};
export const useRegistrySearchStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set) => ({
        reset: () => set(initialState),
        setRegistrySearch: (registrySearch: string) => set({ registrySearch }),
        setLegalEntity: (legalEntity: Company | null) => set({ legalEntity }),
        resetLegalEntity: () => set({ legalEntity: null }),
        setRegistryDocuments: (registryDocuments: RegistryDocument[]) =>
          set({ registryDocuments }),
        setFilteredDocuments: (filteredDocuments: RegistryDocument[]) =>
          set({ filteredDocuments }),
        setCompanySuggestions: (companySuggestions: CompanyNameSuggestion[]) =>
          set({ companySuggestions }),
        selectCompanySuggestion: (
          selectedCompanySuggestion: CompanyNameSuggestion | null,
        ) => set({ selectedCompanySuggestion }),
        setEntityNodes: (entityNodes: Company[]) => set({ entityNodes }),
        setEntityEdges: (entityEdges: { [key: string]: string[] }) =>
          set({ entityEdges }),
        setFilterOptions: (filterOptions: FilterOptionCheckbox[]) =>
          set({ filterOptions }),
        setLoading: (loading: boolean) => set({ loading }),
      })),
    ),
    { name: "RegistrySearchStore" },
  ),
);

useRegistrySearchStore.subscribe(
  (state) => state.selectedCompanySuggestion,
  (selectedCompanySuggestion) => {
    const { setLegalEntity, entityNodes } = useRegistrySearchStore.getState();
    const legalEntity = entityNodes.find(
      (node) =>
        node.ultimateParent ||
        node.name === selectedCompanySuggestion?.companyName,
    );
    if (legalEntity) {
      setLegalEntity(legalEntity);
    }
  },
);

useRegistrySearchStore.subscribe(
  (state) => state.filterOptions,
  (filterOptions) => {
    const { registryDocuments, setFilteredDocuments } =
      useRegistrySearchStore.getState();
    setFilteredDocuments(
      registryDocuments.filter((doc) => {
        const year = doc.effectiveDate?.substring(0, 4) || "";
        return filterOptions.some(
          (option) => option.label === year && option.selected,
        );
      }),
    );
  },
);
