import { Text } from "@capsa-ai/capsa-ui";
import { Handle, Position } from "@xyflow/react";
import { useHover } from "@react-aria/interactions";
import { CompanyNode } from "@/features/registrySearch/types/reactFlow";

type Props = {
  data: CompanyNode;
};

const CustomHandle = ({
  position,
  type,
  id,
  className,
  selected,
}: {
  position: Position;
  type: "target" | "source";
  id?: string;
  className?: string;
  selected?: boolean;
}) => {
  return (
    <Handle
      type={type}
      position={position}
      className={`!bg-secondary !min-w-[7px] !min-h-[7px] !border-base ${className} ${selected ? "!border-primary" : ""}`}
      id={id}
    />
  );
};

export const EntityNode = ({ data }: Props) => {
  const hoverProps = useHover({});

  const selectedStyles =
    "!bg-primary-selected focus-static border-primary transition";

  return (
    <>
      {data.hasParent && (
        <CustomHandle
          position={Position.Top}
          type="target"
          id="target"
          selected={data.selected}
        />
      )}
      <div
        {...hoverProps.hoverProps}
        data-hover={hoverProps.isHovered ? "true" : "false"}
        className={`bg-secondary flex items-center gap-3 px-3 py-2 border rounded-element clickable-forced pressable-forced max-w-[150px] ${data.selected ? selectedStyles : ""}`.trim()}
      >
        {/* <img src={""} className="w-7 h-7 object-cover rounded" /> */}
        <div className="flex flex-col">
          <Text type="label">{data.name}</Text>
          <Text type="caption" color="label">
            {data.city}
          </Text>
        </div>
      </div>
      {data.hasChildren && (
        <CustomHandle
          position={Position.Bottom}
          type="source"
          id="source"
          selected={data.selected}
        />
      )}
    </>
  );
};
