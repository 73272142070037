import { TableAggregationGroup } from "../../../../../api/tableAggregationLayouts";

export const DragItemType = {
  TABLE: "TABLE",
  COLUMN: "COLUMN",
  GROUP: "GROUP",
} as const;

export type DragItemType = (typeof DragItemType)[keyof typeof DragItemType];

export interface TableDragItemData {
  type: typeof DragItemType.TABLE;
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
}

export interface ColumnDragItemData {
  type: typeof DragItemType.COLUMN;
  groupIndex: number;
  columnIndex: number;
}

export interface GroupDragItemData {
  type: typeof DragItemType.GROUP;
  groupIndex: number;
}

export type DragItemData =
  | TableDragItemData
  | ColumnDragItemData
  | GroupDragItemData;

export const DropZoneType = {
  ROW: "ROW",
  COLUMN: "COLUMN",
  GROUP: "GROUP",
} as const;

export type DropZoneType = (typeof DropZoneType)[keyof typeof DropZoneType];

export interface RowDropZoneData {
  type: typeof DropZoneType.ROW;
  groupIndex: number;
  columnIndex: number;
  rowIndex: number;
}

export interface ColumnDropZoneData {
  type: typeof DropZoneType.COLUMN;
  groupIndex: number;
  columnIndex: number;
}

export interface GroupDropZoneData {
  type: typeof DropZoneType.GROUP;
  groupIndex: number;
}

export type DropZoneData =
  | RowDropZoneData
  | ColumnDropZoneData
  | GroupDropZoneData;

export interface TableAggregationGroupWithSelection
  extends TableAggregationGroup {
  isSelected?: boolean;
}
