import OrgUsersList from "./OrgInvitesList";

interface Props {
  orgId: string;
}
export default function OrgInvites({ orgId }: Props) {
  return (
    <div>
      <h1 className="text-lg font-semibold text-gray-900">Pending Invites</h1>
      <OrgUsersList orgId={orgId} />
    </div>
  );
}
