import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { readWebpage } from "../api/webpages";

export const useWebpageQuery = (
  orgId: string,
  dealId: string,
  websiteId: string,
  webpageId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "websites",
      websiteId,
      "webpages",
      webpageId,
    ],
    queryFn: () => readWebpage(user, orgId, dealId, websiteId, webpageId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.ingestion_status === "pending") {
        return 10000;
      }
      return false;
    },
  });
};
