import { useParams } from "react-router-dom";
import { useCompanyProfileTemplateQuery } from "../../hooks/companyProfileTemplates";
import { APIError } from "../../api/shared";
import TabLayout from "../TabLayout";
import UpdateCompanyProfileTemplateButton from "./UpdateCompanyProfileTemplateButton";
import DeleteCompanyProfileTemplateButton from "./DeleteCompanyProfileTemplateButton";
import DealAppToolbar from "../DealAppToolbar";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import Table from "../Table";
import { Property } from "../../api/companyProfileTemplates";

type URLParams = {
  orgId: string;
  companyProfileTemplateId: string;
};

const CompanyProfileTemplateTab = () => {
  const { orgId, companyProfileTemplateId } = useParams() as URLParams;
  const companyProfileTemplateQuery = useCompanyProfileTemplateQuery(
    orgId,
    companyProfileTemplateId,
  );

  // Loading
  if (companyProfileTemplateQuery.isLoading) {
    return (
      <TabLayout
        items={[
          {
            name: "Data Extraction Template",
            href: `/orgs/${orgId}/company-profile-templates`,
          },
        ]}
      >
        <p className="py-4 text-sm text-gray-500">
          Loading Data Extraction Template.
        </p>
      </TabLayout>
    );
  }

  // Insufficient permissions
  if (
    companyProfileTemplateQuery.error instanceof APIError &&
    companyProfileTemplateQuery.error.type === "PermissionError"
  ) {
    return (
      <TabLayout
        items={[
          {
            name: "Data Extraction Template",
            href: `/orgs/${orgId}/company-profile-templates`,
          },
        ]}
      >
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to view this Data Extraction Template."}
        </p>
      </TabLayout>
    );
  }

  // Error
  if (companyProfileTemplateQuery.isError) {
    return (
      <TabLayout
        items={[
          {
            name: "Data Extraction Template",
            href: `/orgs/${orgId}/company-profile-templates`,
          },
        ]}
      >
        <p className="py-4 text-sm text-gray-500">
          Error viewing Data Extraction Template.
        </p>
      </TabLayout>
    );
  }

  const propertyTypeToString = (property: Property) => {
    return (
      <div className="mb-2 mt-2">
        <div className="space-y-2">
          <div>
            {property.data_type.charAt(0).toUpperCase() +
              property.data_type.slice(1)}
            {property.values.length > 0 &&
              `: ${property.values.map((value) => value.content).join(", ")}`}
          </div>
          {property.allow_multiple && <div>Multiple Responses Allowed</div>}
        </div>
      </div>
    );
  };

  const data = companyProfileTemplateQuery.data.properties.map(
    (property, index) => {
      return [
        <p key={`empty-${index}`} />,
        <p key={`name-${index}`}>{property.name}</p>,
        <p key={`type-${index}`}>{propertyTypeToString(property)}</p>,
        <div key={`desc-${index}`} className="whitespace-pre-line max-w-sm">
          {property.description}
        </div>,
        <div key={`examples-${index}`} className="whitespace-pre-line">
          {property.examples.map((example) => example.content).join("\n")}
        </div>,
      ];
    },
  );

  return (
    <TabLayout
      items={[
        {
          name: "Data Extraction Templates",
          href: `/orgs/${orgId}/company-profile-templates`,
        },
        { name: companyProfileTemplateQuery.data.name, href: null },
      ]}
      buttons={[
        <UpdateCompanyProfileTemplateButton
          key="update-button"
          orgId={orgId}
          companyProfileTemplate={companyProfileTemplateQuery.data}
        />,
        <DeleteCompanyProfileTemplateButton
          key="delete-button"
          orgId={orgId}
          companyProfileTemplate={companyProfileTemplateQuery.data}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          tabItems={[
            { name: companyProfileTemplateQuery.data.name, active: true },
          ]}
        />
        <Table
          columnHeaders={["", "Name", "Type", "Description", "Examples"]}
          data={data}
          noDataIcon={DocumentPlusIcon}
          noDataMessage="You don't have any fields in your Data Extraction Template yet."
          noDataButton={
            <UpdateCompanyProfileTemplateButton
              orgId={orgId}
              companyProfileTemplate={companyProfileTemplateQuery.data}
            />
          }
        />
      </div>
    </TabLayout>
  );
};

export default CompanyProfileTemplateTab;
