import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";
import { ExternalFolderContents } from "@/types/files";
import { SharepointFolderContents } from "@/features/sharepoint/types/sharepoint";

export const listSharepointFolders = async (
  user: User,
  orgId: string,
): Promise<SharepointFolderContents> => {
  return await resolveFetch<SharepointFolderContents>(
    fetch(`${baseURL}/orgs/${orgId}/sharepoint/external-folders`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};

export const readSharepointFolder = async (
  user: User,
  orgId: string,
  folderId: string,
): Promise<ExternalFolderContents> => {
  return await resolveFetch<ExternalFolderContents>(
    fetch(`${baseURL}/orgs/${orgId}/sharepoint/external-folders/${folderId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};
