import { useParams } from "react-router-dom";
import CompanyProfileTable from "./CompanyProfileTable";
import FileViewer, { FileViewerParams } from "../../../FileViewer";
import { useState } from "react";
import TabLayout from "../../../TabLayout";
import { useDealQuery } from "../.././../../hooks/deals";
import { useCompanyProfileQuery } from "../../../../hooks/companyProfiles";

type URLParams = {
  orgId: string;
  dealId: string;
  companyProfileId: string;
};

export default function CompanyProfile() {
  const { orgId, dealId, companyProfileId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const companyProfileQuery = useCompanyProfileQuery(
    orgId,
    dealId,
    companyProfileId,
  );
  const [fileViewerParams, setFileViewerParams] = useState<FileViewerParams>({
    fileId: null,
    pageNumber: null,
    websiteId: null,
    webpageId: null,
    waiting: false,
  });

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Data Extractions",
          href: `/orgs/${orgId}/deals/${dealId}/company-profiles`,
        },
        { name: companyProfileQuery.data?.name || "...", href: null },
      ]}
      buttons={[]}
    >
      <div className="flex flex-row p-4 h-[calc(100vh-7.5rem)] sm:h-[calc(100vh-3.5rem)]">
        <div className="w-1/2 h-full mr-2">
          <FileViewer
            orgId={orgId}
            dealId={dealId}
            {...fileViewerParams}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
        <div className="w-1/2 h-full ml-2">
          <CompanyProfileTable
            orgId={orgId}
            dealId={dealId}
            companyProfileId={companyProfileId}
            setFileViewerParams={setFileViewerParams}
          />
        </div>
      </div>
    </TabLayout>
  );
}
