import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createDealUser,
  readDealUser,
  deleteDealUser,
  listDealUsers,
  DealUserCreate,
  DealUserUpdate,
  updateDealUser,
} from "../api/dealUsers";

export const useDealUsersQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "users"],
    queryFn: () => listDealUsers(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useDealUserQuery = (
  orgId: string,
  dealId: string,
  userId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "users", userId],
    queryFn: () => readDealUser(user, orgId, dealId, userId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};

export const useCreateDealUserMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUserCreate) =>
      createDealUser(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "users"],
      });
    },
  });
};

export const useUpdateDealUserMutation = (
  orgId: string,
  dealId: string,
  userId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealUserUpdate) =>
      updateDealUser(user, orgId, dealId, userId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "users"],
      });
    },
  });
};

export const useDeleteDealUserMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ userId }: { userId: string }) =>
      deleteDealUser(user, orgId, dealId, userId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "users"],
      });
    },
  });
};
