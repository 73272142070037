import { baseURL, resolveFetch } from "@/api/shared";
import { CompanyNameSuggestion } from "@/features/companies/types/company";
import { User } from "@/types";

export const getCompanySuggestionsQuick = async (
  user: User,
  orgId: string,
  query: string,
): Promise<CompanyNameSuggestion[]> => {
  return await resolveFetch<CompanyNameSuggestion[]>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/quick-suggestions?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};

export const getCompanySuggestionsExtended = async (
  user: User,
  orgId: string,
  query: string,
): Promise<CompanyNameSuggestion[]> => {
  return await resolveFetch<CompanyNameSuggestion[]>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/extended-suggestions?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
