import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery } from "@tanstack/react-query";
import { listUserDealRoles } from "../api/userDealRoles";

export const useUserDealPermissionsQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "users", "roles"],
    queryFn: async () => {
      const roles = await listUserDealRoles(user, orgId, dealId);
      const permissions = new Set<string>();
      for (const role of roles) {
        for (const permission of role.permissions) {
          permissions.add(permission.permission);
        }
      }
      return permissions;
    },
    enabled: !!user,
    retry: defaultRetryFunc,
    staleTime: 1000 * 60,
  });
};
