import { APIError } from "../../../../api/shared";
import { useDealQuery } from "../../../../hooks/deals";
import { useCompanyProfilesQuery } from "../../../../hooks/companyProfiles";
import { Link, useParams } from "react-router-dom";
import TabLayout from "../../../TabLayout";
import DealAppToolbar from "../../../DealAppToolbar";
import Table from "../../../Table";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";
import CreateCompanyProfileButton from "./CreateCompanyProfileTemplateButton";
import DeleteCompanyProfileButton from "./DeleteCompanyProfileButton";

type URLParams = {
  orgId: string;
  dealId: string;
};

function StatusTag({ status }: { status: string }) {
  if (status === "completed") {
    return (
      <div className="bg-white w-fit p-1 rounded-md">
        <p className="">Processed</p>
      </div>
    );
  }
  if (status === "failed") {
    return (
      <div className="border-red-500 border w-fit p-1 rounded-md">
        <p className=" text-red-500">Failed</p>
      </div>
    );
  }
  return (
    <div className="border-orange-500 border w-fit p-1 rounded-md">
      <p className=" text-orange-500">Processing</p>
    </div>
  );
}

function _CompanyProfileList({
  orgId,
  dealId,
}: {
  orgId: string;
  dealId: string;
}) {
  const companyProfilesQuery = useCompanyProfilesQuery(orgId, dealId);

  // Insufficient permissions
  if (
    companyProfilesQuery.error instanceof APIError &&
    companyProfilesQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to list Data Extractions."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (companyProfilesQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error listing Data Extractions.
          </p>
        </ul>
      </div>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!companyProfilesQuery.isLoading)
    data = companyProfilesQuery.data.map((profile) => {
      return [
        <Link
          key={profile.id}
          to={`/orgs/${orgId}/deals/${dealId}/company-profiles/${profile.id}`}
          className="text-indigo-600"
        >
          {profile.name}
        </Link>,
        <time key={`created_at_${profile.id}`} dateTime={profile.created_at}>
          {new Date(profile.created_at).toLocaleString()}
        </time>,
        <StatusTag
          key={`status_${profile.id}`}
          status={profile.response_status}
        />,
        <div
          key={`actions_${profile.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <DeleteCompanyProfileButton
            orgId={orgId}
            dealId={dealId}
            companyProfileId={profile.id}
          />
        </div>,
      ];
    });

  return (
    <Table
      columnHeaders={["Name", "Created", ""]}
      data={data}
      noDataIcon={DocumentPlusIcon}
      noDataMessage={"You don't have any Data Extractions yet."}
      noDataButton={
        <CreateCompanyProfileButton orgId={orgId} dealId={dealId} />
      }
      isLoading={companyProfilesQuery.isLoading}
    />
  );
}

export default function CompanyProfileList() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Data Extractions",
          href: `/orgs/${orgId}/deals/${dealId}/company-profiles`,
        },
      ]}
      buttons={[
        <CreateCompanyProfileButton
          orgId={orgId}
          key={orgId}
          dealId={dealId}
        />,
      ]}
    >
      <div className="p-4">
        <DealAppToolbar
          orgId={orgId}
          dealId={dealId}
          tabItems={[{ name: "Data Extractions", active: true }]}
        />
        <_CompanyProfileList orgId={orgId} dealId={dealId} />
      </div>
    </TabLayout>
  );
}
