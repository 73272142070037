import { Bars2Icon, Bars3Icon, Bars4Icon } from "@heroicons/react/20/solid";

export type GlobalResponseSizeState =
  | "collapsed"
  | "shortened"
  | "full"
  | "mixture";
export type IndividualResponseSizeState = "collapsed" | "shortened" | "full";

function getStyles(
  state: GlobalResponseSizeState | IndividualResponseSizeState,
) {
  let style0: string;
  let style1: string;
  let style2: string;
  if ("collapsed" === state) {
    style0 =
      "h-6 w-6 flex items-center justify-center bg-white rounded-md border border-1 border-gray-200";
    style1 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style2 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
  } else if ("shortened" === state) {
    style0 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style1 =
      "h-6 w-6 flex items-center justify-center bg-white rounded-md border border-1 border-gray-200";
    style2 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
  } else if ("full" === state) {
    style0 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style1 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style2 =
      "h-6 w-6 flex items-center justify-center bg-white rounded-md border border-1 border-gray-200";
  } else {
    style0 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style1 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
    style2 = "h-6 w-6 flex items-center justify-center hover:cursor-pointer";
  }

  return { style0, style1, style2 };
}

interface Props {
  state: GlobalResponseSizeState | IndividualResponseSizeState;
  setState: (state: IndividualResponseSizeState) => void;
}

export default function ResponseSizeToggle({ state, setState }: Props) {
  const { style0, style1, style2 } = getStyles(state);
  return (
    <div className="flex flex-row rounded-md items-center justify-center bg-gray-100 h-6  border-1 border-gray-200">
      <div className={style0} onClick={() => setState("collapsed")}>
        <Bars2Icon className="h-4 w-4 text-gray-500" />
      </div>
      <div className={style1} onClick={() => setState("shortened")}>
        <Bars3Icon className="h-4 w-4 text-gray-500" />
      </div>
      <div className={style2} onClick={() => setState("full")}>
        <Bars4Icon className="h-4 w-4 text-gray-500" />
      </div>
    </div>
  );
}
