import info from "../info";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

export default function SingleTenantOrgInvite() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="max-w-xl mx-auto">
      <div className="bg-white rounded-md p-8 shadow-md mt-16">
        <div className="flex flex-col p-8">
          <img src="/logo-1.svg" className="w-64 mx-auto mb-6" />
          <h2 className="mx-auto text-xl mb-6 text-gray-700">{info.orgId}</h2>
          <hr className="mb-6" />
          <button
            onClick={() => loginWithRedirect()}
            type="button"
            className="mb-6 mx-auto w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Login
          </button>
          <hr className="mb-6" />
          <p className="text-sm text-gray-700">
            By logging in, you accept our{" "}
            <Link to="/terms-and-conditions" className="text-blue-500">
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link to="/privacy-policy" className="text-blue-500">
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
