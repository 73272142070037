export interface SharepointExternalFolder {
  id: string;
  externalPath: string;
  name: string;
}

export interface SharepointExternalFile {
  id: string;
  externalPath: string;
  size: string;
  owner: string;
  lastEdited: string;
}

export interface SharepointFolderContents {
  folders: SharepointExternalFolder[];
  files: SharepointExternalFile[];
}

export interface CreateSharepointSyncJobResponse {
  jobId: string;
}

export enum SharepointSyncJobStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface SharepointSyncJob {
  id: string;
  orgId: string;
  dealId: string;
  jobStatus: SharepointSyncJobStatus;
  errorCount: number;
  createdAt: string;
  updatedAt: string;
  externalFolderIds: string[];
  externalFileIds: string[];
  syncedFolderIds: string[];
  syncedFileIds: string[];
}
