import { baseURL, resolveFetch } from "@/api/shared";
import { RegistryList } from "@/features/companies/types/company";
import { User } from "@/types";

export const getCompanyHeirarchy = async (
  user: User,
  orgId: string,
  graphId: string,
): Promise<RegistryList> => {
  return await resolveFetch<RegistryList>(
    fetch(
      `${baseURL}/orgs/${orgId}/companies-search/company-hierarchy?graph_id=${encodeURIComponent(graphId)}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
    true,
  );
};
