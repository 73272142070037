import { useAuth0 } from "@auth0/auth0-react";
import Intercom, {
  shutdown as shutdown_intercom,
} from "@intercom/messenger-js-sdk";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useUserOrgPermissionsQuery } from "../../hooks";
import info from "../../info";
import { getNavigation, getUserNavigation } from "./common";
import NarrowOrgDashboardLayout from "./NarrowOrgDashboardLayout";
import WideOrgDashboardLayout from "./WideOrgDashboardLayout";

function useIsNarrow(threshold: number) {
  const [isNarrow, setIsNarrow] = useState(window.innerWidth < threshold);
  useEffect(() => {
    function handleResize() {
      setIsNarrow(window.innerWidth < threshold);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return isNarrow;
}

function useNavigation() {
  const { pathname } = useLocation();
  const posthog = usePostHog();
  return getNavigation(info.orgId, pathname, posthog);
}

function useUserNavigation() {
  const { logout } = useAuth0();
  return getUserNavigation(logout);
}

type URLParams = {
  orgId: string;
};

export default function OrgDashboardLayout() {
  const { orgId } = useParams() as URLParams;
  const navigation = useNavigation();
  const userNavigation = useUserNavigation();
  const isNarrow = useIsNarrow(640);
  const permissionQuery = useUserOrgPermissionsQuery(orgId);
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      const hmac = user["https://api.capsa.ai/intercom_hmac"];
      Intercom({
        app_id: info.intercomAppId,
        user_id: user.sub,
        name: user.name,
        email: user.email,
        user_hash: hmac,
      });
    } else {
      shutdown_intercom();
    }
  }, [user, info.orgId]);

  if (permissionQuery.isLoading) {
    return <p>Loading</p>;
  }

  if (permissionQuery.isError || permissionQuery.data.size === 0) {
    return <Navigate to="/users/current/orgs" />;
  }

  if (isNarrow) {
    return (
      <NarrowOrgDashboardLayout
        navigation={navigation}
        userNavigation={userNavigation}
      />
    );
  }

  return (
    <WideOrgDashboardLayout
      navigation={navigation}
      userNavigation={userNavigation}
    />
  );
}
