import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface OrgInviteRead {
  id: string;
  org_id: string;
  user_email: string;
  roles: string[];
  created_at: string;
}

export const listOrgInvites = async (
  user: User,
  orgId: string,
): Promise<OrgInviteRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/invites`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const readOrgInvite = async (
  user: User,
  orgId: string,
  inviteId: string,
): Promise<OrgInviteRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/invites/${inviteId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface OrgInviteCreate {
  user_email: string;
  roles: string[];
}

export const createOrgInvite = async (
  user: User,
  orgId: string,
  data: OrgInviteCreate,
): Promise<OrgInviteRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/invites`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export interface OrgInviteUpdate {
  roles: string[];
}

export const updateOrgInvite = async (
  user: User,
  orgId: string,
  inviteId: string,
  data: OrgInviteUpdate,
): Promise<OrgInviteRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/invites/${inviteId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const deleteOrgInvite = async (
  user: User,
  orgId: string,
  inviteId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/invites/${inviteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface UserInviteRead {
  id: string;
  org_id: string;
  user_email: string;
  roles: string[];
  created_at: string;
}

export const listUserInvites = async (
  user: User,
): Promise<UserInviteRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/users/current/invites`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export type InviteResponse = "accept" | "decline";

export interface UserInviteUpdate {
  response: InviteResponse;
}

export const updateUserInvite = async (
  user: User,
  inviteId: string,
  data: UserInviteUpdate,
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/users/current/invites/${inviteId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};
