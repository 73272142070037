import { TrashIcon } from "@heroicons/react/20/solid";
import { hasPermission } from "../../../utils";
import Spinner from "../../Spinner";
import { useDeleteDealFileMutation } from "../../../hooks/deals";
import { useUserDealPermissionsQuery } from "../../../hooks/userDealRoles";

interface Props {
  orgId: string;
  dealId: string;
  fileId: string;
}

export default function DeleteFileButton({ orgId, dealId, fileId }: Props) {
  const mutation = useDeleteDealFileMutation(orgId, dealId);
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);

  switch (hasPermission(permissionsQuery, "files.delete")) {
    case "loading":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <Spinner h="4" w="4" />
        </button>
      );
    case "true":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          onClick={() => mutation.mutate({ fileId })}
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    case "false":
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
          title="Insufficient permissions, contact org admin."
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
    default:
      return (
        <button
          type="button"
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
          disabled
        >
          <TrashIcon className="text-red-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
        </button>
      );
  }
}
