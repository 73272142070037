import React from "react";
import { Breadcrumbs } from "@capsa-ai/capsa-ui";
import RegistrySearch from "@/features/registrySearch/pages/RegistrySearch";
import { useNavigate } from "react-router-dom";

export const RegistrySearchView = () => {
  const navigate = useNavigate();
  return (
    <div className="bg min-h-screen">
      <div className="flex items-center px-4 h-12 border-b gap-1.5">
        <div className="pl-3 w-full">
          <Breadcrumbs
            crumbs={[{ label: "Legal Entity Search", to: "null" }]}
            onNavigate={navigate}
          />
        </div>
      </div>
      <div className="flex flex-col px-4 py-6 pb-24 gap-4">
        <RegistrySearch />
      </div>
    </div>
  );
};
