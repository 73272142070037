import {
  RouterProvider,
  Navigate,
  createBrowserRouter,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import OrgDashboardLayout from "./components/OrgDashboardLayout";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import "./index.css";
import "@capsa-ai/capsa-ui/dist/style.css";
import OrgUsersUpdate from "./components/OrgUsersUpdate";
import UserDashboardLayout from "./components/UserDashboardLayout";
import UsersTab from "./components/UsersTab";
import DealsTab from "./components/DealsTab";
import DealTab from "./components/DealTab";
import DealUserChatThread from "./components/DealTab/ChatSubTab/DealUserChatThread";
import DealUserChatThreadList from "./components/DealTab/ChatSubTab/DealUserChatThreadList";
import DealChatThread from "./components/DealTab/ChatSubTab/DealChatThread";
import DealChatThreadList from "./components/DealTab/ChatSubTab/DealChatThreadList";
import FilesList from "./components/DealTab/FilesSubTab/FilesList";
import DealQuestionThreadList from "./components/DealTab/QuestionThreadsSubTab/DealQuestionThreadList";
import DealQuestionThreadsCreate from "./components/DealTab/DealQuestionThreadsCreate";
import QuestionTemplatesTab from "./components/QuestionTemplatesTab";
import QuestionTemplatesCreate from "./components/QuestionTemplatesTab/QuestionTemplatesCreate";
import QuestionTemplateTab from "./components/QuestionTemplateTab";
import QuestionTemplatesUpdate from "./components/QuestionTemplatesTab/QuestionTemplatesUpdate";
import DealQuestionThread from "./components/DealTab/QuestionThreadsSubTab/DealQuestionThread";
import { Auth0Provider } from "@auth0/auth0-react";
import Authenticate from "./components/Authenticate";
import UnverifiedRoute from "./components/UnverifiedRoute";
import VerifyEmail from "./components/VerifyEmail";
import SingleTenantOrgInvite from "./components/SingleTenantUserOrgs";
import info from "./info";
import LoggedOutDashboardLayout from "./components/LoggedOutDashboardLayout";
import { Worker } from "@react-pdf-viewer/core";
import { UploadProvider } from "./components/UploadProvider";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Search from "./components/DealTab/SearchSubTab/Search";
import DealQuestionThreadsUpdate from "./components/DealTab/QuestionThreadsSubTab/DealQuestionThreadsUpdate";
import WebsitesList from "./components/DealTab/WebsitesSubTab/WebsitesList";
import WebsitesCreate from "./components/DealTab/WebsitesCreate";
import DealsUpdate from "./components/DealsTab/DealsUpdate";
import DealQuestionThreadExportsCreate from "./components/DealTab/QuestionThreadsSubTab/DealQuestionThreadExportsCreate";
import RegistrySearchJobList from "./components/DealTab/RegistrySearchJobsSubTab/RegistrySearchJobList";
import File from "./components/DealTab/FilesSubTab/File";
import UsersList from "./components/DealTab/UsersSubTab/UsersList";
import DealUsersUpdate from "./components/DealTab/UsersSubTab/UsersUpdate";
import DealUsersCreate from "./components/DealTab/DealUsersCreate";
import DealChatThreadExportsCreate from "./components/DealTab/ChatSubTab/DealChatThreadExportsCreate";
import Applications from "./components/DealTab/Applications";
import TableAggregationsList from "./components/DealTab/TableAggregrationsSubTab/TableAggregationsList";
import TableAggregationsCreate from "./components/DealTab/TableAggregrationsSubTab/TableAggregationsCreate";
import TableAggregation from "./components/DealTab/TableAggregrationsSubTab/TableAggregation";
import Templates from "./components/TemplatesTab/Templates";
import CompanyProfileTemplatesTab from "./components/CompanyProfileTemplatesTab";
import CompanyProfileTemplatesCreate from "./components/CompanyProfileTemplatesTab/CompanyProfileTemplatesCreate";
import CompanyProfileTemplatesUpdate from "./components/CompanyProfileTemplatesTab/CompanyProfileTemplatesUpdate";
import CompanyProfileTemplateTab from "./components/CompanyProfileTemplateTab";
import TableAggregationsUpdate from "./components/DealTab/TableAggregrationsSubTab/TableAggregationsUpdate";

import CompanyProfileList from "./components/DealTab/CompanyProfileSubtab/list/CompanyProfileList";
import CompanyProfileCreate from "./components/DealTab/CompanyProfileSubtab/create/CompanyProfileCreate";
import CompanyProfile from "./components/DealTab/CompanyProfileSubtab/view/CompanyProfile";
import CreateCompanyFeatureFlag from "@/features/companies/pages/CreateCompanyFeatureFlag";
import DealsCreate from "@/components/DealsTab/DealsCreate";
import { RegistrySearchView } from "@/features/registrySearch/pages/RegistrySearchView";
import { DealRegistrySearchView } from "@/features/registrySearch/pages/DealRegistrySearchView";
import Website from "./components/DealTab/WebsitesSubTab/Website";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

function getUserRoutes() {
  return {
    path: "/users/current",
    element: (
      <AuthenticatedRoute>
        <UserDashboardLayout />
      </AuthenticatedRoute>
    ),
    children: [
      {
        path: "orgs",
        element: <SingleTenantOrgInvite />,
      },
    ],
  };
}

function getRootRoute() {
  return {
    path: "/",
    element: <Navigate to={`/orgs/${info.orgId}`} />,
  };
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/authenticate",
      element: (
        <UnauthenticatedRoute>
          <LoggedOutDashboardLayout />
        </UnauthenticatedRoute>
      ),
      children: [
        {
          path: "",
          element: <Authenticate />,
        },
      ],
    },
    {
      path: "/terms-and-conditions",
      element: <LoggedOutDashboardLayout />,
      children: [
        {
          path: "",
          element: <TermsAndConditions />,
        },
      ],
    },
    {
      path: "/privacy-policy",
      element: <LoggedOutDashboardLayout />,
      children: [
        {
          path: "",
          element: <PrivacyPolicy />,
        },
      ],
    },
    {
      path: "/verify-email",
      element: (
        <UnverifiedRoute>
          <UserDashboardLayout />
        </UnverifiedRoute>
      ),
      children: [
        {
          path: "",
          element: <VerifyEmail />,
        },
      ],
    },
    {
      path: "/orgs/:orgId",
      element: (
        <AuthenticatedRoute>
          <OrgDashboardLayout />
        </AuthenticatedRoute>
      ),
      children: [
        {
          path: "deals/:dealId",
          element: <DealTab />,
          children: [
            {
              path: "files/:fileId",
              element: <File />,
            },

            {
              path: "files",
              element: <FilesList />,
            },
            {
              path: "table-aggregations",
              element: <TableAggregationsList />,
            },
            {
              path: "table-aggregations-create",
              element: <TableAggregationsCreate />,
            },
            {
              path: "table-aggregations-update/:tableAggregationId",
              element: <TableAggregationsUpdate />,
            },
            {
              path: "table-aggregations/:tableAggregationId",
              element: <TableAggregation />,
            },
            {
              path: "websites-create",
              element: <WebsitesCreate />,
            },
            {
              path: "websites",
              element: <WebsitesList />,
            },
            {
              path: "websites/:websiteId",
              element: <Website />,
            },
            {
              path: "registry-search-jobs",
              element: <RegistrySearchJobList />,
            },
            {
              path: "registry-search-jobs-create",
              element: <DealRegistrySearchView />,
            },

            {
              path: "question-threads/:dealQuestionThreadId",
              element: <DealQuestionThread />,
            },
            {
              path: "question-threads-create",
              element: <DealQuestionThreadsCreate />,
            },
            {
              path: "question-threads-update/:dealQuestionThreadId",
              element: <DealQuestionThreadsUpdate />,
            },
            {
              path: "question-threads/:dealQuestionThreadId/exports-create",
              element: <DealQuestionThreadExportsCreate />,
            },
            { path: "question-threads", element: <DealQuestionThreadList /> },
            {
              path: "chat-threads/:dealChatThreadId",
              element: <DealUserChatThread />,
            },
            { path: "chat-threads", element: <DealUserChatThreadList /> },
            {
              path: "chat-threads/:dealChatThreadId/exports-create",
              element: <DealChatThreadExportsCreate />,
            },
            {
              path: "chat-threads-su/:dealChatThreadId",
              element: <DealChatThread />,
            },
            { path: "chat-threads-su", element: <DealChatThreadList /> },
            {
              path: "search",
              element: <Search />,
            },
            {
              path: "users-create",
              element: <DealUsersCreate />,
            },
            {
              path: "users-update/:userId",
              element: <DealUsersUpdate />,
            },
            {
              path: "users",
              element: <UsersList />,
            },
            { path: "", element: <Applications /> },
            { path: "company-profiles", element: <CompanyProfileList /> },
            {
              path: "company-profiles-create",
              element: <CompanyProfileCreate />,
            },
            {
              path: "company-profiles/:companyProfileId",
              element: <CompanyProfile />,
            },
          ],
        },
        {
          path: "deals",
          element: <DealsTab />,
        },
        {
          path: "save-deal",
          element: <DealsCreate />,
        },
        {
          path: "deals-create",
          element: <CreateCompanyFeatureFlag />,
        },
        {
          path: "deals-update/:dealId",
          element: <DealsUpdate />,
        },
        {
          path: "templates",
          element: <Templates />,
        },
        {
          path: "legal-entity-search",
          element: <RegistrySearchView />,
        },
        {
          path: "company-profile-templates/:companyProfileTemplateId",
          element: <CompanyProfileTemplateTab />,
        },
        {
          path: "company-profile-templates",
          element: <CompanyProfileTemplatesTab />,
        },
        {
          path: "company-profile-templates-create",
          element: <CompanyProfileTemplatesCreate />,
        },
        {
          path: "company-profile-templates-update/:companyProfileTemplateId",
          element: <CompanyProfileTemplatesUpdate />,
        },
        {
          path: "question-templates/:questionTemplateId",
          element: <QuestionTemplateTab />,
        },
        {
          path: "question-templates",
          element: <QuestionTemplatesTab />,
        },
        {
          path: "question-templates-create",
          element: <QuestionTemplatesCreate />,
        },
        {
          path: "question-templates-update/:questionTemplateId",
          element: <QuestionTemplatesUpdate />,
        },
        // {
        //   path: "invites-update/:inviteId",
        //   element: <OrgInvitesUpdate />,
        // },
        // {
        //   path: "invites-create",
        //   element: <OrgInvitesCreate />,
        // },
        {
          path: "users-update/:userId",
          element: <OrgUsersUpdate />,
        },
        {
          path: "users",
          element: <UsersTab />,
        },
        // {
        //   path: "api-keys",
        //   element: <APIKeysTab />,
        // },
        // {
        //   path: "signing-keys",
        //   element: <SigningKeysTab />,
        // },
        // {
        //   path: "billing",
        //   element: <Billing />,
        // },
        {
          path: "",
          element: <Navigate to="deals" />,
        },
      ],
    },
    getUserRoutes(),
    getRootRoute(),
    { path: "*", element: <Navigate to="/" /> },
  ]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Auth0Provider
        domain={info.auth0Domain}
        clientId={info.auth0ClientId}
        authorizationParams={{
          audience: "https://api.capsa.ai",
          redirect_uri: `${info.appURL}/authenticate`,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <UploadProvider>
            <RouterProvider router={router} />
          </UploadProvider>
          <ReactQueryDevtools
            initialIsOpen={false}
            panelProps={{ style: { display: "none" } }}
            toggleButtonProps={{ style: { display: "none" } }}
          />
        </QueryClientProvider>
      </Auth0Provider>
    </Worker>
  );
}

export default App;
