import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createWebsiteCrawlJob,
  readWebsiteCrawlJob,
  listWebsiteCrawlJobs,
  WebsiteCrawlJobCreate,
  deleteWebsiteCrawlJob,
} from "../api/websiteCrawlJobs";
import { useParams } from "react-router-dom";

type URLParams = {
  orgId: string;
  dealId: string;
};

export const useWebsiteCrawlJobsQuery = (params: {
  status?: ("pending" | "completed" | "failed")[];
}) => {
  const { orgId, dealId } = useParams() as URLParams;
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "website-crawl-jobs"],
    queryFn: () => listWebsiteCrawlJobs(user, orgId, dealId, params.status),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((file) => file.status === "pending")) {
        return 10000;
      }
      return false;
    },
  });
};

export const useWebsiteCrawlJobQuery = (
  orgId: string,
  dealId: string,
  websiteCrawlJobsId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [
      orgId,
      "deals",
      dealId,
      "website-crawl-jobs",
      websiteCrawlJobsId,
    ],
    queryFn: () => readWebsiteCrawlJob(user, orgId, dealId, websiteCrawlJobsId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.status === "pending") {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateWebsiteCrawlJobMutation = (
  orgId: string,
  dealId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: WebsiteCrawlJobCreate) =>
      createWebsiteCrawlJob(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "website-crawl-jobs"],
      });
    },
  });
};

export const useDeleteWebsiteCrawlJobMutation = () => {
  const { orgId, dealId } = useParams() as URLParams;
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ websiteCrawlJobId }: { websiteCrawlJobId: string }) =>
      deleteWebsiteCrawlJob(user, orgId, dealId, websiteCrawlJobId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "website-crawl-jobs"],
      });
    },
  });
};
