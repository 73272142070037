import { Button } from "@capsa-ai/capsa-ui";
import { TagField } from "@capsa-ai/capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { sendEvent } from "@/lib/posthog";
import React, { useEffect, useState } from "react";

const TabAliases = () => {
  const { nextTab, previousTab, aliases, setAliases, removeAlias } =
    useCreateCompanyStore();
  const [input, setInput] = useState("");

  useEffect(() => {
    sendEvent("viewed_aliases_tab", {
      feature: "create_company",
    });
  }, []);

  const handleAddAlias = (alias: string) => {
    setAliases([...aliases, { id: alias, label: alias }]);
  };

  const handleRemoveAlias = (alias: string) => {
    removeAlias(alias);
  };

  const handleSkip = () => {
    const newAliases = aliases.filter((alias) => alias.preventRemoval === true);
    setAliases(newAliases);
    nextTab();
  };

  const handleNext = () => {
    if (input.length > 0) {
      handleAddAlias(input);
    }
    nextTab();
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <TagField
          label="Aliases"
          tags={aliases}
          onRemoveTag={handleRemoveAlias}
          onAddTag={handleAddAlias}
          onEmptyEnter={nextTab}
          autoFocus
          value={input}
          onChange={setInput}
          tooltip="Add any additional aliases that the company is reffered by."
          description="Press enter after typing to add an alias"
        />
      </div>
      <div className="flex gap-1.5">
        <Button
          variant="secondary"
          iconLeft="chevronLeft"
          onClick={previousTab}
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button variant="secondary" onClick={handleSkip}>
          Skip
        </Button>
        <Button iconRight="chevronRight" onClick={handleNext}>
          Next
        </Button>
      </div>
    </>
  );
};

export default TabAliases;
