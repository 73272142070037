import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Document {
  id: string;
  registry_identifier: string;
}

export interface DocumentUploadJobData {
  company_name: string;
  company_id_in_registry: string;
  documents_to_sync: Document[];
  documents_synced: Document[];
}

export interface DocumentUploadJobList {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "completed" | "failed";
  data: DocumentUploadJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const listDocumentUploadJobs = async (
  user: User,
  orgId: string,
  dealId: string,
  status?: ("pending" | "in_progress" | "completed" | "failed")[],
): Promise<DocumentUploadJobList[]> => {
  const url = new URL(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/document-upload-jobs`,
  );
  if (status) {
    status.forEach((s) => url.searchParams.append("status", s));
  }
  return await resolveFetch(
    fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface DocumentUploadJobRead {
  id: string;
  org_id: string;
  deal_id: string;
  status: "pending" | "in_progress" | "completed" | "failed";
  data: DocumentUploadJobData;
  documents_uploaded: number;
  created_at: string;
  updated_at: string;
}

export const readDocumentUploadJob = async (
  user: User,
  orgId: string,
  dealId: string,
  documentUploadJobId: string,
): Promise<DocumentUploadJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/document-upload-jobs/${documentUploadJobId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export const createDocumentUploadJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: DocumentUploadJobData,
): Promise<DocumentUploadJobRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/document-upload-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user?.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const deleteDocumentUploadJob = async (
  user: User,
  orgId: string,
  dealId: string,
  documentUploadJobId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/document-upload-jobs/${documentUploadJobId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
