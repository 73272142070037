import { useNavigate, useParams } from "react-router-dom";
import { useDealQuery } from "../../../hooks/deals";
import { APIError } from "../../../api/shared";
import { Controller, useForm } from "react-hook-form";
import TabLayout from "../../TabLayout";
import {
  useTableAggregationQuery,
  useUpdateTableAggregationMutation,
} from "../../../hooks/tableAggregations";
import { TableAggregationRead } from "../../../api/tableAggregations";

type URLParams = {
  orgId: string;
  dealId: string;
  tableAggregationId: string;
};

interface FormProps {
  orgId: string;
  dealId: string;
  tableAggregation: TableAggregationRead;
}

interface TemplateAggregationFormValues {
  name: string;
}

function TableAggregationUpdateForm({
  orgId,
  dealId,
  tableAggregation,
}: FormProps) {
  const navigate = useNavigate();
  const { control, handleSubmit, reset } =
    useForm<TemplateAggregationFormValues>({
      defaultValues: {
        name: tableAggregation.name || "",
      },
    });
  const mutation = useUpdateTableAggregationMutation(
    orgId,
    dealId,
    tableAggregation.id,
  );

  return (
    <form
      onSubmit={handleSubmit((values) => {
        mutation.mutate(
          {
            name: values.name || null,
            layout: tableAggregation.data.layout,
            aggregated_tables: tableAggregation.data.aggregated_tables,
          },
          {
            onSuccess: () => {
              navigate(
                `/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregation.id}`,
              );
              reset();
            },
          },
        );
      })}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Table Aggregation Name
              </label>
              <div className="mt-1">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      id="name"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      {...field}
                      value={field.value ?? ""}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() =>
              navigate(`/orgs/${orgId}/deals/${dealId}/table-aggregations`)
            }
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
}

export default function TableAggregationsUpdate() {
  const { orgId, dealId, tableAggregationId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const tableAggregationQuery = useTableAggregationQuery(
    orgId,
    dealId,
    tableAggregationId,
  );

  // Loading
  if (tableAggregationQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Loading table aggregation.
          </p>
        </ul>
      </div>
    );
  }

  // Insufficient permissions
  if (
    tableAggregationQuery.error instanceof APIError &&
    tableAggregationQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to view this table aggregation."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (tableAggregationQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error viewing this table aggregation.
          </p>
        </ul>
      </div>
    );
  }

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Table Aggregations",
          href: `/orgs/${orgId}/deals/${dealId}/table-aggregations`,
        },
        {
          name: tableAggregationQuery.data.name,
          href: `/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregationId}`,
        },
        { name: "Update", href: null },
      ]}
    >
      <div className="p-4">
        <TableAggregationUpdateForm
          orgId={orgId}
          dealId={dealId}
          tableAggregation={tableAggregationQuery.data}
        />
      </div>
    </TabLayout>
  );
}
