import { UsersIcon } from "@heroicons/react/24/outline";
import { APIError } from "../../../api/shared";
import { useOrgInvitesQuery } from "../../../hooks";
import Table from "../../Table";
import DeleteOrgInviteButton from "./DeleteOrgInviteButton";
import UpdateOrgInviteButton from "./UpdateOrgInvitesButton";

interface Props {
  orgId: string;
}

export default function OrgInvitesList({ orgId }: Props) {
  const orgInvitesQuery = useOrgInvitesQuery(orgId);

  // Insufficient permissions
  if (
    orgInvitesQuery.error instanceof APIError &&
    orgInvitesQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list Org Invites."}
        </p>
      </ul>
    );
  }

  // Error
  if (orgInvitesQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">Error listing Org Invites.</p>
      </ul>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!orgInvitesQuery.isLoading) {
    data = orgInvitesQuery.data.map((invite) => {
      return [
        <p key={`email-${invite.id}`}>{invite.user_email}</p>,
        <p key={`roles-${invite.id}`}>
          {invite.roles.map((role) => role).join(", ")}
        </p>,
        <div
          key={`actions-${invite.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateOrgInviteButton orgId={orgId} inviteId={invite.id} />
          <DeleteOrgInviteButton orgId={orgId} invite={invite} />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Email", "Roles", ""]}
      data={data}
      noDataIcon={UsersIcon}
      noDataMessage="You don't have any Org Invites yet."
      isLoading={orgInvitesQuery.isLoading}
    />
  );
}
