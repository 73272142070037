import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  useCreateWebsiteMutation,
  useWebsitesQuery,
} from "../../hooks/websites";
import TabLayout from "../TabLayout";
import { useDealQuery } from "../../hooks/deals";
import { useCreateWebsiteCrawlJobMutation } from "@/hooks/websiteCrawlJobs";
import { usePostHog } from "posthog-js/react";

type URLParams = {
  orgId: string;
  dealId: string;
};

export default function WebsitesCreate() {
  const { orgId, dealId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();
  const mutation = useCreateWebsiteMutation(orgId, dealId);
  const createCrawlJobMutation = useCreateWebsiteCrawlJobMutation(
    orgId,
    dealId,
  );
  const websitesQuery = useWebsitesQuery(orgId, dealId);
  const posthog = usePostHog();

  if (websitesQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Loading websites...</p>
        </ul>
      </div>
    );
  }

  if (websitesQuery.error) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Error loading websites.</p>
        </ul>
      </div>
    );
  }

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Websites", href: `/orgs/${orgId}/deals/${dealId}/websites` },
        { name: "Create", href: null },
      ]}
    >
      <div className="p-4">
        <form
          onSubmit={handleSubmit((values) => {
            if (posthog.isFeatureEnabled("website_crawl_jobs")) {
              createCrawlJobMutation.mutate(
                {
                  url: values.url,
                },
                {
                  onSuccess: () => {
                    navigate(`/orgs/${orgId}/deals/${dealId}/websites`);
                    reset();
                  },
                },
              );
              return;
            }
            mutation.mutate(
              {
                url: values.url,
              },
              {
                onSuccess: () => {
                  navigate(`/orgs/${orgId}/deals/${dealId}/websites`);
                  reset();
                },
              },
            );
          })}
        >
          <div className="flex border-b-[1px] border-gray-200 pb-4">
            <p className="text-sm max-w-lg text-gray-700">Add a new website.</p>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="url"
                  className="block text-sm font-medium text-gray-700"
                >
                  URL
                </label>
                <div className="mt-1">
                  <Controller
                    name="url"
                    control={control}
                    render={({ field }) => (
                      <input
                        id="url"
                        type="text"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        {...field}
                        value={field.value ?? ""}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="py-6">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(`/orgs/${orgId}/deals`)}
                type="button"
                className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
    </TabLayout>
  );
}
