import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface Cell {
  kind: "content" | "rowHeader" | "columnHeader" | "stubHead" | "description";
  row_index: number;
  column_index: number;
  content: string;
}

export interface Table {
  row_count: number;
  column_count: number;
  cells: Cell[];
}

export interface TablePointer {
  file_id: string;
  table_id: string;
}

export interface TableAggregationColumn {
  tables: TablePointer[];
}

export interface TableAggregationGroup {
  columns: TableAggregationColumn[];
}

export interface AggregatedTable {
  table: Table;
}

export interface TableAggregationData {
  layout: TableAggregationGroup[];
  aggregated_tables: AggregatedTable[] | null;
}

export interface TableAggregationCreate {
  name: string | null;
  layout: TableAggregationGroup[];
}

export interface File {
  id: string;
  name: string;
}

export interface FileTable {
  id: string;
  file_id: string;
  page_number: number | null;
  number: number;
  table: Table;
}

export interface TableAggregationRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string;
  files: Record<string, File>;
  tables: Record<string, FileTable>;
  data: TableAggregationData;
  status: "pending" | "complete" | "failed";
  created_at: string;
}

export const createTableAggregation = async (
  user: User,
  orgId: string,
  dealId: string,
  data: TableAggregationCreate,
): Promise<TableAggregationRead> => {
  console.log(data);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const listTableAggregations = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<TableAggregationRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const readTableAggregation = async (
  user: User,
  orgId: string,
  dealId: string,
  tableAggregationId: string,
): Promise<TableAggregationRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregationId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface TableAggregationUpdate {
  name: string | null;
  layout: TableAggregationGroup[];
  aggregated_tables: AggregatedTable[] | null;
}

export const updateTableAggregation = async (
  user: User,
  orgId: string,
  dealId: string,
  tableAggregationId: string,
  data: TableAggregationUpdate,
): Promise<TableAggregationRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};

export const deleteTableAggregation = async (
  user: User,
  orgId: string,
  dealId: string,
  tableAggregationId: string,
): Promise<void> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregationId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
