import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  children: ReactElement;
}

export default function UnauthenticatedRoute({ children }: Props) {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
}
