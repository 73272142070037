import { useParams } from "react-router-dom";
import CreateCompanyProfileTemplateButton from "./CreateCompanyProfileTemplateButton";
import { CompanyProfileTemplatesList } from "./CompanyProfileTemplatesList";
import TabLayout from "../TabLayout";

type URLParams = {
  orgId: string;
};

export default function CompanyProfileTemplatesTab() {
  const { orgId } = useParams() as URLParams;

  return (
    <TabLayout
      items={[{ name: "Data Extraction Templates", href: null }]}
      buttons={[
        <CreateCompanyProfileTemplateButton key={orgId} orgId={orgId} />,
      ]}
    >
      <div className="p-4">
        <div className="flex">
          <p className="text-sm max-w-2xl text-gray-700">
            Data Extraction Templates contain a set of Data Extraction Templates
            that you can apply to deals.
          </p>
        </div>
        <CompanyProfileTemplatesList orgId={orgId} />
      </div>
    </TabLayout>
  );
}
