import { useAuth } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import {
  DealChatThreadExportCreate,
  createDealChatThreadExport,
} from "../api/dealChatThreadExports";

export const useCreateDealChatThreadExportMutation = (
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: DealChatThreadExportCreate) =>
      createDealChatThreadExport(user, orgId, dealId, dealChatThreadId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [
          orgId,
          "deals",
          dealId,
          "chat-threads",
          dealChatThreadId,
          "exports",
        ],
      });
    },
  });
};
