import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import RolesMultiSelect from "../../RolesMultiSelect";
import { RoleRead } from "../../../api/roles";
import {
  useDealUserQuery,
  useUpdateDealUserMutation,
} from "../../../hooks/dealUsers";
import { useDealQuery } from "../../../hooks/deals";
import TabLayout from "../../TabLayout";

type URLParams = {
  orgId: string;
  dealId: string;
  userId: string;
};

export default function DealUsersUpdate() {
  const { orgId, dealId, userId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm();
  const dealUserQuery = useDealUserQuery(orgId, dealId, userId);
  const mutation = useUpdateDealUserMutation(orgId, dealId, userId);

  if (dealUserQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4 pt-6">
          <p className="py-4 text-sm text-gray-500">Loading...</p>
        </ul>
      </div>
    );
  }

  if (dealUserQuery.error) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4 pt-6">
          <p className="py-4 text-sm text-gray-500">Error loading user.</p>
        </ul>
      </div>
    );
  }

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        { name: "Users", href: `/orgs/${orgId}/deals/${dealId}/users` },
        {
          name: dealUserQuery.data.email || "...",
          href: `/orgs/${orgId}/deals/${dealId}/users/${userId}`,
        },
        { name: "Update", href: null },
      ]}
    >
      <div className="p-4">
        <form
          onSubmit={handleSubmit((values) => {
            const roles = values.roles as RoleRead[];
            mutation.mutate(
              { roles: roles.map((r) => r.id) },
              {
                onSuccess: () => {
                  navigate(`/orgs/${orgId}/deals/${dealId}/users`);
                  reset();
                },
              },
            );
          })}
        >
          <div className="flex border-b-[1px] border-gray-200 pb-4">
            <p className="text-sm max-w-lg text-gray-700">
              Edit roles for {dealUserQuery.data.email}.
            </p>
          </div>
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="roles"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Roles
                  </label>
                  <div className="mt-1">
                    <Controller
                      name="roles"
                      control={control}
                      render={({ field }) => (
                        <RolesMultiSelect
                          orgId={orgId as string}
                          type="deal"
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-6">
            <div className="flex justify-end">
              <button
                onClick={() => navigate(`/orgs/${orgId}/deal/${dealId}/users`)}
                type="button"
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </TabLayout>
  );
}
