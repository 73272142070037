import { useNavigate, useParams } from "react-router-dom";
import {
  useDealQuestionThreadQuery,
  useUpdateDealQuestionThreadMutation,
} from "../../../hooks";
import { useDealQuery } from "../../../hooks/deals";
import { APIError } from "../../../api/shared";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import remarkGfm from "remark-gfm";
import {
  DealQuestionThreadRead,
  QuestionAndMessages,
  QuestionThread,
  ShortAnswer,
} from "../../../api/dealQuestionThreads";
import {
  ArrowDownIcon,
  ArrowPathIcon,
  ArrowUpIcon,
  ArrowUturnLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import OpenAI from "openai";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import TabLayout from "../../TabLayout";

type URLParams = {
  orgId: string;
  dealId: string;
  dealQuestionThreadId: string;
};

interface FormProps {
  orgId: string;
  dealId: string;
  dealQuestionThread: DealQuestionThreadRead;
}

interface QuestionThreadFormValues {
  name: string;
  questions: {
    originalValue: string | null;
    value: string;
    messages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
    short_answer: ShortAnswer | null;
  }[];
}

function getAnswerContent(messages: OpenAI.Chat.ChatCompletionMessageParam[]) {
  const finalMessage = messages[messages.length - 1];
  if (finalMessage.role === "assistant" && finalMessage.content) {
    return (
      <Markdown remarkPlugins={[remarkGfm]}>
        {finalMessage.content.replace(/【([a-zA-Z0-9]{5})】/g, "")}
      </Markdown>
    );
    return;
  }
  throw new Error("No assistant message found.");
}

function AnswerText({
  editedQuestions,
  refreshedQuestions,
  originalValue,
  messages,
}: {
  editedQuestions: Set<string>;
  refreshedQuestions: Set<string>;
  originalValue: string | null;
  messages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
}) {
  const [expand, setExpand] = useState(false);
  if (originalValue === null) {
    return (
      <div className="prose prose-sm max-w-none flex-grow text-indigo-600">
        <p className="flex-grow line-clamp-1">
          This is a new question, answer to be generated after submission.
        </p>
      </div>
    );
  }
  if (messages === null) {
    throw new Error("Messages cannot be null");
  }
  if (editedQuestions.has(originalValue)) {
    return (
      <div className="prose prose-sm max-w-none flex-grow text-indigo-600">
        <p className="flex-grow line-clamp-1">
          This question has been updated, answer to be generated after
          submission.
        </p>
      </div>
    );
  }
  if (refreshedQuestions.has(originalValue)) {
    return (
      <div className="prose prose-sm max-w-none flex-grow text-indigo-600">
        <p className=" flex-grow line-clamp-1">
          Refresh requested, answer to be refreshed after submission.
        </p>
      </div>
    );
  }
  return (
    <div className="prose prose-sm max-w-none flex-grow">
      {expand ? (
        <p
          className="flex-grow hover:cursor-pointer"
          onClick={() => setExpand(false)}
        >
          {getAnswerContent(messages)}
        </p>
      ) : (
        <p
          className="flex-grow line-clamp-1 hover:cursor-pointer"
          onClick={() => setExpand(true)}
        >
          {getAnswerContent(messages)}
        </p>
      )}
    </div>
  );
}

function RefreshButton({
  editedQuestions,
  refreshedQuestions,
  setRefreshedQuestions,
  originalValue,
  messages,
}: {
  editedQuestions: Set<string>;
  refreshedQuestions: Set<string>;
  setRefreshedQuestions: (value: Set<string>) => void;
  originalValue: string | null;
  messages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
}) {
  if (originalValue === null) {
    return (
      <button
        title="Refresh answer"
        type="button"
        disabled
        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        <ArrowPathIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
      </button>
    );
  }
  if (messages === null) {
    throw new Error("Messages cannot be null");
  }
  if (editedQuestions.has(originalValue)) {
    return (
      <button
        title="Refresh answer"
        type="button"
        disabled
        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        <ArrowPathIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
      </button>
    );
  }
  if (refreshedQuestions.has(originalValue)) {
    return (
      <button
        title="Reset answer"
        type="button"
        onClick={() => {
          const newState = new Set(refreshedQuestions);
          newState.delete(originalValue);
          setRefreshedQuestions(newState);
        }}
        className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
      >
        <ArrowUturnLeftIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
      </button>
    );
  }
  return (
    <button
      title="Refresh answer"
      type="button"
      onClick={() => {
        const newState = new Set(refreshedQuestions);
        newState.add(originalValue);
        setRefreshedQuestions(newState);
      }}
      className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
    >
      <ArrowPathIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
    </button>
  );
}

function DealQuestionThreadUpdateForm({
  orgId,
  dealId,
  dealQuestionThread,
}: FormProps) {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, register, watch } =
    useForm<QuestionThreadFormValues>({
      defaultValues: {
        name: dealQuestionThread.name,
        questions: dealQuestionThread.thread.questions_and_messages.map(
          (q) => ({
            originalValue: q.question.content,
            value: q.question.content,
            messages: q.messages,
            short_answer: q.short_answer,
          }),
        ),
      },
    });
  const { fields, append, remove, insert, move } = useFieldArray({
    control,
    name: "questions",
  });
  const [editedQuestions, setEditedQuestions] = useState<Set<string>>(
    new Set(),
  );
  const [refreshedQuestions, setRefreshedQuestions] = useState<Set<string>>(
    new Set(),
  );
  const mutation = useUpdateDealQuestionThreadMutation(
    orgId,
    dealId,
    dealQuestionThread.id,
  );

  const questionValues = watch("questions");
  useEffect(() => {
    const ids = new Set<string>();
    questionValues.forEach((question) => {
      if (
        question.originalValue !== null &&
        question.value !== question.originalValue
      ) {
        ids.add(question.originalValue);
      }
    });
    setEditedQuestions(ids);
  }, [JSON.stringify(questionValues)]);

  return (
    <form
      onSubmit={handleSubmit((values) => {
        const name =
          values.name !== dealQuestionThread.name ? values.name : null;
        const questionsAndMessages: QuestionAndMessages[] =
          values.questions.map((question) => {
            if (question.originalValue === null) {
              return {
                question: {
                  content: question.value,
                },
                messages: null,
                short_answer: null,
              };
            }
            if (editedQuestions.has(question.originalValue)) {
              return {
                question: {
                  content: question.value,
                },
                messages: null,
                short_answer: null,
              };
            }
            if (refreshedQuestions.has(question.originalValue)) {
              return {
                question: {
                  content: question.value,
                },
                messages: null,
                short_answer: null,
              };
            }
            return {
              question: {
                content: question.value,
              },
              messages: question.messages,
              short_answer: question.short_answer,
            };
          });
        let thread: QuestionThread | null = {
          questions_and_messages: questionsAndMessages,
        };
        if (
          JSON.stringify(thread.questions_and_messages) ===
          JSON.stringify(dealQuestionThread.thread.questions_and_messages)
        ) {
          thread = null;
        }
        mutation.mutate(
          {
            name,
            thread,
          },
          {
            onSuccess: () => {
              navigate(
                `/orgs/${orgId}/deals/${dealId}/question-threads/${dealQuestionThread.id}`,
              );
              reset();
            },
          },
        );
      })}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Question Thread Name
              </label>
              <div className="mt-1">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <input
                      id="name"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      {...field}
                      value={field.value ?? ""}
                    />
                  )}
                />
              </div>
            </div>

            {fields.map((field, index) => {
              return (
                <div key={index} className="sm:col-span-6">
                  <label
                    htmlFor="questions"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Question {index + 1}
                  </label>
                  <div className="mt-1">
                    <div className="flex flex-row">
                      <textarea
                        key={field.id}
                        {...register(`questions.${index}.value`)}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm whitespace-pre-wrap"
                      />
                      <div className="flex">
                        <button
                          title="Move question up"
                          type="button"
                          onClick={() => move(index, index - 1)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowUpIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Move question down"
                          type="button"
                          onClick={() => move(index, index + 1)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <ArrowDownIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Add question below"
                          type="button"
                          onClick={() =>
                            insert(index + 1, {
                              originalValue: null,
                              value: "",
                              messages: null,
                              short_answer: null,
                            })
                          }
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <PlusIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                        <button
                          title="Remove question"
                          type="button"
                          onClick={() => remove(index)}
                          className="ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                        >
                          <XMarkIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-row mt-2">
                      <AnswerText
                        editedQuestions={editedQuestions}
                        refreshedQuestions={refreshedQuestions}
                        originalValue={field.originalValue}
                        messages={field.messages}
                      />
                      <div className="flex">
                        <RefreshButton
                          editedQuestions={editedQuestions}
                          refreshedQuestions={refreshedQuestions}
                          setRefreshedQuestions={setRefreshedQuestions}
                          originalValue={field.originalValue}
                          messages={field.messages}
                        />
                        {[0, 1, 2].map((i) => (
                          <button
                            key={i}
                            className="invisible ml-2 inline-flex items-center h-8 rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50"
                          >
                            <ArrowPathIcon className="text-gray-400 group-hover:text-gray-500 flex-shrink-0 h-4 w-4" />
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  append({
                    originalValue: null,
                    value: "",
                    messages: null,
                    short_answer: null,
                  });
                }}
                type="button"
                className="rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PlusIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="flex justify-end">
          <button
            onClick={() => navigate(`/orgs/${orgId}/question-templates`)}
            type="button"
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Update
          </button>
        </div>
      </div>
    </form>
  );
}

export default function DealQuestionThreadsUpdate() {
  const { orgId, dealId, dealQuestionThreadId } = useParams() as URLParams;
  const dealQuery = useDealQuery(orgId, dealId);
  const questionThreadQuery = useDealQuestionThreadQuery(
    orgId,
    dealId,
    dealQuestionThreadId,
  );

  // Loading
  if (questionThreadQuery.isLoading) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">Loading question thread.</p>
        </ul>
      </div>
    );
  }

  // Insufficient permissions
  if (
    questionThreadQuery.error instanceof APIError &&
    questionThreadQuery.error.type === "PermissionError"
  ) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            {"You don't have permission to view this question thread."}
          </p>
        </ul>
      </div>
    );
  }

  // Error
  if (questionThreadQuery.isError) {
    return (
      <div>
        <ul className="divide-y divide-gray-200 border-b-[1px] border-gray-200 my-4">
          <p className="py-4 text-sm text-gray-500">
            Error viewing this question thread.
          </p>
        </ul>
      </div>
    );
  }

  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Question Threads",
          href: `/orgs/${orgId}/deals/${dealId}/question-threads`,
        },
        {
          name: questionThreadQuery.data.name,
          href: `/orgs/${orgId}/deals/${dealId}/question-threads/${dealQuestionThreadId}`,
        },
        { name: "Update", href: null },
      ]}
    >
      <div className="p-4">
        <DealQuestionThreadUpdateForm
          orgId={orgId}
          dealId={dealId}
          dealQuestionThread={questionThreadQuery.data}
        />
      </div>
    </TabLayout>
  );
}
