import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface WebpageRead {
  id: string;
  org_id: string;
  deal_id: string;
  website_id: string;
  url: string;
  text: string;
  html: string;
  markdown: string;
  screenshot_container: string;
  screenshot_blob: string;
  screenshot_download_url: string;
  loaded_at: string;
  ingestion_status: "pending" | "completed" | "failed";
  created_at: string;
}

export const readWebpage = async (
  user: User,
  orgId: string,
  dealId: string,
  websiteId: string,
  webpageId: string,
): Promise<WebpageRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/websites/${websiteId}/webpages/${webpageId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
