import { Button } from "@capsa-ai/capsa-ui";
import { Divider } from "@capsa-ai/capsa-ui";
import { Icon } from "@capsa-ai/capsa-ui";
import { IconButton } from "@capsa-ai/capsa-ui";
import { Text } from "@capsa-ai/capsa-ui";
import SyncSharepointModal from "@/features/sharepoint/components/SyncSharepointModal";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import React, { useMemo, useRef, useState } from "react";

interface FileUploadProps {
  onFileUpload: (files: FileList) => void;
}

const FileUploadComponent: React.FC<FileUploadProps> = ({ onFileUpload }) => {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [sharepointFiles, setSharepointFiles] = useState<string[]>([]);
  const [sharepointFolders, setSharepointFolders] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { SharepointImportButton, openModal } = useSharepointStore();

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
      onFileUpload(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      onFileUpload(e.target.files);
    }
  };

  const removeFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSharepointImport = (data: {
    files: string[];
    folders: string[];
  }) => {
    setSharepointFiles(data.files);
    setSharepointFolders(data.folders);
  };

  const resetSharepointFiles = () => {
    setSharepointFiles([]);
    setSharepointFolders([]);
  };

  const sharepointImportString = useMemo(() => {
    const files = sharepointFiles.length;
    const folders = sharepointFolders.length;

    return `${files > 0 ? `${files} file${files > 1 ? "s" : ""}${folders > 0 ? ", " : ""} ` : ""}${folders > 0 ? `${folders} folder${folders > 1 ? "s" : ""}` : ""} selected`.trim();
  }, [sharepointFiles, sharepointFolders]);

  return (
    <>
      <SyncSharepointModal handleImport={handleSharepointImport} />
      <div className="rounded-layout content-left space-y-3 relative w-full content-left flex flex-col border p-3 h-full">
        <div
          className="w-full flex flex-col justify-center items-center space-y-2"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragEnter}
          onDrop={handleDrop}
        >
          <div
            className={`transition bg gap-4 p-10 w-full ${files.length > 0 || sharepointFiles.length > 0 || sharepointFolders.length > 0 ? "h-fit" : "h-80"} border border-dashed rounded-element flex flex-col justify-center items-center ${
              dragging ? "border-primary bg-secondary" : "border"
            }`}
          >
            <Icon size="hero" type="upload" withBG />
            <div className="flex flex-col gap-3 justify-start">
              <Text color="label">
                Drag and drop /{" "}
                <span
                  className="text-primary clickable pressable"
                  onClick={triggerFileInput}
                >
                  browse files
                </span>{" "}
                to upload
              </Text>
              <input
                ref={fileInputRef}
                type="file"
                multiple
                className="hidden"
                onChange={handleFileSelect}
              />

              {SharepointImportButton && (
                <>
                  <div className="flex-center w-full gap-3">
                    <Divider />
                    <Text type="caption" color="subdued">
                      or
                    </Text>
                    <Divider />
                  </div>
                  <SharepointImportButton />
                </>
              )}
            </div>
          </div>
        </div>
        {(sharepointFiles.length > 0 || sharepointFolders.length > 0) && (
          <div className="border flex justify-between items-center px-3 py-2 gap-2 rounded">
            <Icon type="sharepoint" />
            <Text type="label" color="label" className="flex-1">
              {sharepointImportString}
            </Text>
            <Button variant="secondary" onClick={openModal}>
              Update
            </Button>
            <IconButton
              variant="tertiary"
              onClick={resetSharepointFiles}
              icon="delete"
            />
          </div>
        )}
        {files.length > 0 && (
          <div
            className="flex flex-col space-y-2 overflow-y-auto"
            style={{ maxHeight: "350px" }}
          >
            {files.map((file, index) => (
              <div
                key={index}
                className="border flex justify-between items-center px-3 py-2 rounded"
              >
                <Text type="label" color="label">
                  {file.name}
                </Text>
                <IconButton
                  variant="tertiary"
                  onClick={() => removeFile(index)}
                  icon="delete"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadComponent;
