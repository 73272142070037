import { APIError } from "../../../../api/shared";
import {
  useCompanyProfileQuery,
  useCreateCompanyProfileExportMutation,
} from "../../../../hooks/companyProfiles";
import { FileViewerParams } from "../../../FileViewer";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import Table from "../../../Table";
import { CitationInstance } from "../../../../messageUtils";
import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { CheckIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { usePostHog } from "posthog-js/react";
import { useAuth0 } from "@auth0/auth0-react";
import info from "../../../../info";

import { CompanyProfileProperty } from "../../../../api/companyProfiles";

import { marked } from "marked";

import { useUserDealPermissionsQuery } from "../../../../hooks/userDealRoles";
import { hasPermission } from "../../../../utils";
import { useState, useEffect, useRef } from "react";
import { CompanyProfileExportFileFormat } from "../../../../api/companyProfiles";

interface Props {
  orgId: string;
  dealId: string;
  companyProfileId: string;
  setFileViewerParams: (params: FileViewerParams) => void;
}

const Spinner = () => {
  return (
    <div role="status">
      <span className="relative flex h-2.5 w-2.5">
        <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-indigo-400 opacity-75" />
        <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-indigo-500" />
      </span>
      <span className="sr-only">Loading...</span>
    </div>
  );
};

function Source({
  propertyIdx,
  citationInstance,
  setFileViewerParams,
}: {
  propertyIdx: number;
  citationInstance: CitationInstance;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  const searchResult = citationInstance.searchResult;
  const link_styles =
    "text-blue-500 hover:cursor-pointer no-underline hover:underline";
  if ("file_id" in searchResult) {
    return (
      <div
        className="inline-flex"
        onClick={() =>
          setFileViewerParams({
            fileId: searchResult.file_id,
            pageNumber: searchResult.page_number,
            websiteId: null,
            webpageId: null,
            waiting: true,
          })
        }
      >
        <a
          className={link_styles}
          title={`${searchResult.file_name}, page ${searchResult.page_number}`}
        >
          {propertyIdx}.{citationInstance.citation_number}
        </a>
      </div>
    );
  } else {
    return (
      <div
        className="inline-flex"
        onClick={() =>
          setFileViewerParams({
            fileId: null,
            pageNumber: null,
            websiteId: searchResult.website_id,
            webpageId: searchResult.webpage_id,
            waiting: false,
          })
        }
      >
        <a className={link_styles} title={searchResult.url}>
          {propertyIdx}.{citationInstance.citation_number}
        </a>
      </div>
    );
  }
}

//This method returns a single link to a source including its citation number and the file name and page
function FullSource({
  propertyIdx: property_idx,
  citationInstance,
  setFileViewerParams,
}: {
  propertyIdx: number;
  citationInstance: CitationInstance;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  const searchResult = citationInstance.searchResult;
  const link_styles =
    "text-blue-500 hover:cursor-pointer no-underline hover:underline";
  if ("file_id" in searchResult) {
    return (
      <li
        className="inline-flex"
        onClick={() =>
          setFileViewerParams({
            fileId: searchResult.file_id,
            pageNumber: searchResult.page_number,
            websiteId: null,
            webpageId: null,
            waiting: true,
          })
        }
      >
        <a
          className={link_styles}
          title={`${searchResult.file_name}, page ${searchResult.page_number}`}
        >
          {property_idx}.{citationInstance.citation_number}.{" "}
          {searchResult.file_name}, page {searchResult.page_number}
        </a>
      </li>
    );
  } else {
    return (
      <li
        onClick={() =>
          setFileViewerParams({
            fileId: null,
            pageNumber: null,
            websiteId: searchResult.website_id,
            webpageId: searchResult.webpage_id,
            waiting: false,
          })
        }
      >
        <a className={link_styles}>
          {property_idx}.{citationInstance.citation_number}. {searchResult.url}
        </a>
      </li>
    );
  }
}

function Sources({
  propertyIdx,
  citationInstances,
  setFileViewerParams,
}: {
  propertyIdx: number;
  citationInstances: CitationInstance[];
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    // align text to the right
    <div className="flex-none text-right ml-auto pb-1">
      {citationInstances.map((citationInstance, index) => (
        <React.Fragment key={index}>
          <Source
            propertyIdx={propertyIdx}
            citationInstance={citationInstance}
            setFileViewerParams={setFileViewerParams}
          />
          {index < citationInstances.length - 1 && <span>, </span>}
        </React.Fragment>
      ))}
    </div>
  );
}

function CopyToClipboard({
  propertyResponse,
}: {
  propertyResponse: string | null;
}) {
  const [showCopied, setShowCopied] = useState(false);
  const posthog = usePostHog();
  const { user } = useAuth0();

  const copyToClipboard = async () => {
    if (!propertyResponse) {
      return;
    }

    const html = await marked(propertyResponse);

    const items = [
      new ClipboardItem({
        "text/html": new Blob([html], { type: "text/html" }),
        "text/plain": new Blob([propertyResponse], { type: "text/plain" }),
      }),
    ];

    await navigator.clipboard.write(items);
    setShowCopied(true);
    if (user) {
      posthog.capture("copy_company_profile_table", {
        org_id: info.orgId,
        user_id: user.sub,
        user_email: user.email,
      });
    }
    setTimeout(() => setShowCopied(false), 2000);
  };

  if (showCopied) {
    return (
      <div className="absolute rounded top-2 right-1 items-center hover:cursor-pointer group-hover:flex hidden shadow-lg bg-white ring-1 ring-black ring-opacity-10 p-1">
        <CheckIcon className="h-5 w-5 text-green-500 animate-appear-left-right" />
      </div>
    );
  }

  return (
    <div
      className="absolute rounded top-2 right-1 hidden items-center hover:cursor-pointer group-hover:flex shadow-lg bg-white ring-1 ring-black ring-opacity-10 p-1"
      onClick={copyToClipboard}
    >
      <ClipboardIcon className="h-5 w-5 text-indigo-600" />
    </div>
  );
}

function CellWithCopyButton({
  property,
  propertyIdx,
  setFileViewerParams,
}: {
  property: CompanyProfileProperty;
  propertyIdx: number;
  setFileViewerParams: (params: FileViewerParams) => void;
}) {
  return (
    <div className="relative group">
      <div className="flex flex-wrap items-end">
        <Markdown className="flex-auto" remarkPlugins={[remarkGfm]}>
          {property.response}
        </Markdown>
        <Sources
          propertyIdx={propertyIdx}
          citationInstances={property.citations}
          setFileViewerParams={setFileViewerParams}
        />
      </div>
      <CopyToClipboard propertyResponse={property.response} />
    </div>
  );
}

interface ExportButtonProps {
  orgId: string;
  dealId: string;
  companyProfileId: string;
}

export function ExportButton({
  orgId,
  dealId,
  companyProfileId,
}: ExportButtonProps) {
  const permissionsQuery = useUserDealPermissionsQuery(orgId, dealId);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [includeCitations, setIncludeCitations] = useState(false);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const mutation = useCreateCompanyProfileExportMutation(
    orgId,
    dealId,
    companyProfileId,
  );

  const handleAction = (format: CompanyProfileExportFileFormat) => {
    mutation.mutate({
      file_format: format,
      include_citations: includeCitations,
    });
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const outsideDropdownButton =
        dropdownButtonRef.current &&
        !dropdownButtonRef.current.contains(event.target as Node);
      const outsideDropdownMenu =
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target as Node);
      if (outsideDropdownButton && outsideDropdownMenu) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderButton = (disabled: boolean, title?: string) => (
    <div className="relative inline-flex rounded-md h-7 text-sm">
      <button
        type="button"
        onClick={() => handleAction("pdf")}
        className="inline-flex items-center justify-center px-2.5 font-medium bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 disabled:opacity-50"
        disabled={disabled}
        style={{ borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px" }}
        {...(title && { title })}
      >
        Export
      </button>
      <button
        type="button"
        onClick={() => {
          setDropdownOpen(!dropdownOpen);
        }}
        ref={dropdownButtonRef}
        className="inline-flex items-center justify-center w-6 bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none disabled:opacity-50 border-l border-gray-400"
        disabled={disabled}
        style={{ borderTopRightRadius: "4px", borderBottomRightRadius: "4px" }}
      >
        <svg viewBox="0 0 30 30" className="w-2.5 h-full fill-current">
          <polygon points="15,17.4 4.8,7 2,9.8 15,23 28,9.8 25.2,7" />
        </svg>
      </button>
      {dropdownOpen && (
        <div
          ref={dropdownMenuRef}
          className="absolute right-1 top-6 mt-2 w-56 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-10 z-10"
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <button
              onClick={() => handleAction("pdf")}
              className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 w-full text-left"
              role="menuitem"
            >
              Export PDF (Default)
            </button>
            <button
              onClick={() => handleAction("docx")}
              className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 w-full text-left"
              role="menuitem"
            >
              Export DOC
            </button>
            <div className="flex items-center px-4 py-2 text-sm text-gray-700 w-full">
              <input
                id="include-citations"
                type="checkbox"
                checked={includeCitations}
                onChange={() => setIncludeCitations(!includeCitations)}
                className="mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded cursor-pointer"
              />
              <label
                htmlFor="include-citations"
                className="select-none cursor-pointer"
              >
                Include citations
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  switch (hasPermission(permissionsQuery, "company_profiles.read")) {
    case "loading":
      return renderButton(true);
    case "true":
      return renderButton(false);
    case "false":
      return renderButton(true, "Insufficient permissions, contact org admin.");
    default:
      return renderButton(true);
  }
}

const CompanyProfileTable: React.FC<Props> = ({
  orgId,
  dealId,
  companyProfileId,
  setFileViewerParams,
}) => {
  const companyProfileQuery = useCompanyProfileQuery(
    orgId,
    dealId,
    companyProfileId,
  );

  // Loading
  if (companyProfileQuery.isLoading) {
    return <p>Loading...</p>;
  }

  // Insufficient permissions
  if (
    companyProfileQuery.error instanceof APIError &&
    companyProfileQuery.error.type === "PermissionError"
  ) {
    return <p>{"You don't have permission to view this Data Extraction."}</p>;
  }

  // Error
  if (companyProfileQuery.isError) {
    return <p>Error viewing Data Extraction.</p>;
  }

  let data: React.ReactElement[][] = [];
  const sources: React.ReactElement[][] = [];
  if (!companyProfileQuery.isLoading) {
    const queryData = companyProfileQuery.data;
    const profileData = queryData.data;
    data = profileData.properties.map((property, propertyIdx) => {
      return [
        <div key={`property-name-${propertyIdx}`} className="flex items-center">
          <div className="text-sm">{property.name}</div>
        </div>,
        <div key={`property-response-${propertyIdx}`} className="">
          {property.response ? (
            <span className="prose prose-sm max-w-screen-lg">
              <CellWithCopyButton
                property={property}
                propertyIdx={propertyIdx}
                setFileViewerParams={setFileViewerParams}
              />
            </span>
          ) : (
            <div className="flex items-center p-2">
              <Spinner />
            </div>
          )}
        </div>,
      ];
    });
    profileData.properties.forEach((property, property_idx) => {
      if (property.citations) {
        property.citations.forEach((citationInstance, citationIdx) => {
          sources.push([
            <div key={`citation-${property_idx}-${citationIdx}`}>
              <FullSource
                propertyIdx={property_idx}
                citationInstance={citationInstance}
                setFileViewerParams={setFileViewerParams}
              />
            </div>,
          ]);
        });
      }
    });
  }
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b border-gray-200 pb-4 mr-1">
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">
          Data Extraction: {companyProfileQuery.data.name}
        </h1>
        <ExportButton
          orgId={orgId}
          dealId={dealId}
          companyProfileId={companyProfileId}
        />
      </div>
      <div className="flex flex-col flex-grow overflow-auto pb-1 space-y-4 pl-1 mb-1">
        <div className="mr-2 flex-grow">
          <Table
            columnHeaders={["Property", "Content"]}
            data={data}
            noDataIcon={DocumentPlusIcon}
            noDataMessage={"No properties extracted yet"}
            isLoading={companyProfileQuery.isLoading}
          />
        </div>
        <div className="flex-grow pb-1">
          <ul className="divide-y divide-gray-200 space-y-4 mr-2 pl-1">
            <Table
              columnHeaders={["References"]}
              data={sources}
              noDataIcon={DocumentPlusIcon}
              noDataMessage={"No properties extracted yet"}
              isLoading={companyProfileQuery.isLoading}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileTable;
