import { User } from "../../../types";
import { baseURL, resolveFetch } from "../../../api/shared";

interface SharepointConfig {
  serviceEnabled: boolean;
}

export const getConfig = async (
  user: User,
  orgId: string,
): Promise<SharepointConfig> => {
  return await resolveFetch<SharepointConfig>(
    fetch(`${baseURL}/orgs/${orgId}/sharepoint/configs/current`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
    true,
  );
};
