import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";

export interface WebsiteCrawlJobRead {
  id: string;
  org_id: string;
  deal_id: string;
  url: string;
  items_crawled: number;
  status: "pending" | "completed" | "failed";
  created_at: string;
}

export const listWebsiteCrawlJobs = async (
  user: User,
  orgId: string,
  dealId: string,
  status?: ("pending" | "completed" | "failed")[],
): Promise<WebsiteCrawlJobRead[]> => {
  const url = new URL(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/website-crawl-jobs`,
  );
  if (status) {
    status.forEach((s) => url.searchParams.append("status", s));
  }
  return await resolveFetch(
    fetch(url.toString(), {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const readWebsiteCrawlJob = async (
  user: User,
  orgId: string,
  dealId: string,
  websiteCrawlJobId: string,
): Promise<WebsiteCrawlJobRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/website-crawl-jobs/${websiteCrawlJobId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface WebsiteCrawlJobCreate {
  url: string;
}

export const createWebsiteCrawlJob = async (
  user: User,
  orgId: string,
  dealId: string,
  data: WebsiteCrawlJobCreate,
): Promise<WebsiteCrawlJobRead> => {
  const response = await resolveFetch<WebsiteCrawlJobRead>(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/website-crawl-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
  return response;
};

export const deleteWebsiteCrawlJob = async (
  user: User,
  orgId: string,
  dealId: string,
  websiteCrawlJobId: string,
): Promise<null> => {
  const response = await resolveFetch<null>(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/website-crawl-jobs/${websiteCrawlJobId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
  return response;
};
