import { User } from "../types";
import { baseURL, resolveFetch, mapToSnakeCase } from "./shared";

export interface DealRead {
  id: string;
  org_id: string;
  name: string;
  company_name: string;
  company_aliases: string[];
  created_at: string;
}

export const listDeals = async (
  user: User,
  orgId: string,
): Promise<DealRead[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export const readDeal = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DealRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface CreateDealResponse {
  id: string;
  dealName: string;
  companyName: string;
  companyAliases: string[];
}

interface RegistryFile {
  id: string;
  registryIdentifier: string;
}

export interface CreateDealData {
  name: string;
  companyName: string;
  companyLegalName: string;
  companyIdInRegistry: string;
  companyAliases: string[];
  website: string | null;
  registryFiles: RegistryFile[];
  countryCode: string;
}

export const createDeal = async (
  user: User,
  orgId: string,
  data: CreateDealData,
): Promise<CreateDealResponse> => {
  const body = mapToSnakeCase<typeof data>(data);
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(body),
    }),
    true,
  );
};

export interface DealUpdate {
  name: string;
  company_name: string;
  company_aliases: string[];
}

export const updateDeal = async (
  user: User,
  orgId: string,
  dealId: string,
  data: DealUpdate,
): Promise<DealRead> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    }),
  );
};

export const deleteDeal = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};
