import { APIError } from "../../../api/shared";
import {
  useCreateDealUserChatThreadMutation,
  useDealQuestionThreadQuery,
} from "../../../hooks";
import { FileViewerParams } from "../../FileViewer";
import {
  Question,
  QuestionAndMessages,
} from "../../../api/dealQuestionThreads";
import OpenAI from "openai";
import {
  CitationInstance,
  getMessagesAndCitationInstances,
} from "../../../messageUtils";
import DealQuestionThreadMarkdown from "./DealQuestionThreadMarkdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import ResponseSizeToggle, {
  GlobalResponseSizeState,
  IndividualResponseSizeState,
} from "../../ResponseSizeToggle";
import { ChatCompletionMessageParam } from "openai/resources";

interface Props {
  orgId: string;
  dealId: string;
  dealQuestionThreadId: string;
  setFileViewerParams: (params: FileViewerParams) => void;
}

interface Answer {
  content: string;
  citationInstances: CitationInstance[];
}

interface QuestionAndAnswer {
  question: Question;
  messages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
  shortMessages: OpenAI.Chat.ChatCompletionMessageParam[] | null;
  answer: Answer | null;
  shortAnswer: Answer | null;
}

function getQuestionsAndAnswers(questionsAndMessages: QuestionAndMessages[]) {
  const questionsAndAnswers: QuestionAndAnswer[] = questionsAndMessages.map(
    ({ question, messages, short_answer }) => {
      if (messages === null) {
        return {
          question,
          messages,
          shortMessages: null,
          answer: null,
          shortAnswer: null,
        };
      }
      const { messagesAndCitationInstances, shortAnswer } =
        getMessagesAndCitationInstances(messages, short_answer);
      const finalMessage =
        messagesAndCitationInstances[messagesAndCitationInstances.length - 1];
      if (
        finalMessage.message.role === "assistant" &&
        finalMessage.message.content
      ) {
        return {
          question,
          messages,
          shortMessages:
            short_answer === null
              ? messages
              : [
                  ...messages.slice(0, -1),
                  { role: "assistant", content: short_answer.content },
                ],
          answer: {
            content: finalMessage.message.content,
            citationInstances: finalMessage.citationInstances,
          },
          shortAnswer:
            shortAnswer === null
              ? null
              : {
                  content: shortAnswer.content,
                  citationInstances: finalMessage.citationInstances,
                },
        };
      }
      throw new Error("No assistant message found.");
    },
  );
  return questionsAndAnswers;
}

const useSearchParamScrollPosition = (query: UseQueryResult) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const questionIndexString = searchParams.get("questionIndex");
    const questionIndex = questionIndexString
      ? parseInt(questionIndexString)
      : null;
    if (questionIndex !== null) {
      const element = document.getElementById(`question-${questionIndex}`);
      if (element) {
        element.scrollIntoView();
        setSearchParams({});
      }
    }
  }, [query.data, searchParams]);
};

interface ResponseSizeStates {
  globalState: GlobalResponseSizeState;
  individualStates: IndividualResponseSizeState[];
}

function useResponseSizeStates(initState: IndividualResponseSizeState) {
  const [responseSizeStates, setResponseSizeStates] =
    useState<ResponseSizeStates>({
      globalState: initState,
      individualStates: [],
    });

  const setGlobalState = (state: IndividualResponseSizeState) => {
    setResponseSizeStates((s) => ({
      globalState: state,
      individualStates: s.individualStates.map(() => state),
    }));
  };

  const setIndividualState = (
    index: number,
    state: IndividualResponseSizeState,
  ) => {
    setResponseSizeStates((s) => {
      const individualStates = [...s.individualStates];
      individualStates[index] = state;
      const globalState = individualStates.every((s) => s === state)
        ? state
        : "mixture";
      return {
        globalState: globalState,
        individualStates: individualStates,
      };
    });
  };

  const setIndividualStateCount = (count: number) => {
    if (count !== responseSizeStates.individualStates.length) {
      setResponseSizeStates((s) => ({
        globalState: s.globalState,
        individualStates: Array(count).fill(s.globalState),
      }));
    }
  };

  return [
    responseSizeStates,
    setGlobalState,
    setIndividualState,
    setIndividualStateCount,
  ] as const;
}

const DealQuestionWindow: React.FC<Props> = ({
  orgId,
  dealId,
  dealQuestionThreadId,
  setFileViewerParams,
}) => {
  const dealDealQuestionThreadQuery = useDealQuestionThreadQuery(
    orgId,
    dealId,
    dealQuestionThreadId,
  );
  useSearchParamScrollPosition(dealDealQuestionThreadQuery);
  const [
    responseSizeStates,
    setGlobalState,
    setIndividualState,
    setIndividualStateCount,
  ] = useResponseSizeStates("shortened");
  const createDealUserChatThreadMutation = useCreateDealUserChatThreadMutation(
    orgId,
    dealId,
  );
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleCreateChatThread = (
    index: number,
    messages: ChatCompletionMessageParam[],
  ) => {
    const fixedMessages = messages.map((message) => {
      if (message.content === "") {
        return {
          ...message,
          content: "Question could not be answered.",
        };
      } else {
        return message;
      }
    });
    createDealUserChatThreadMutation.mutate(
      { thread: { messages: fixedMessages } },
      {
        onSuccess: (data) => {
          setSearchParams(
            { questionIndex: index.toString() },
            { replace: true },
          );
          navigate(`/orgs/${orgId}/deals/${dealId}/chat-threads/${data.id}`);
        },
      },
    );
  };

  // Loading
  if (dealDealQuestionThreadQuery.isLoading) {
    return <p>Loading...</p>;
  }

  // Insufficient permissions
  if (
    dealDealQuestionThreadQuery.error instanceof APIError &&
    dealDealQuestionThreadQuery.error.type === "PermissionError"
  ) {
    return <p>{"You don't have permission to view this question thread."}</p>;
  }

  // Error
  if (dealDealQuestionThreadQuery.isError) {
    return <p>Error viewing question thread.</p>;
  }

  const thread = dealDealQuestionThreadQuery.data.thread;
  const questionsAndAnswers = getQuestionsAndAnswers(
    thread.questions_and_messages,
  );
  const responseStatus = dealDealQuestionThreadQuery.data.response_status;
  setIndividualStateCount(questionsAndAnswers.length);
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row border-b-[1px] border-gray-200 pb-2 mb-2">
        <h1 className="text-lg font-semibold text-gray-900 flex-grow">
          Questions
        </h1>
        <ResponseSizeToggle
          state={responseSizeStates.globalState}
          setState={setGlobalState}
        />
      </div>
      <div className="flex-grow overflow-auto">
        <ul className="divide-y divide-gray-200 space-y-4 mr-4">
          {questionsAndAnswers.map(
            (
              { question, messages, shortMessages, answer, shortAnswer },
              index,
            ) => {
              return (
                <li id={`question-${index}`} key={index}>
                  <div className="pb-4 pt-2">
                    <div className="overflow-hidden">
                      <div className="flex pb-3 items-center justify-between">
                        <ResponseSizeToggle
                          state={responseSizeStates.individualStates[index]}
                          setState={(state) => setIndividualState(index, state)}
                        />
                        <div className="p-1 ml-auto">
                          {messages !== null &&
                            responseSizeStates.individualStates[index] !==
                              "collapsed" && (
                              <ArrowTopRightOnSquareIcon
                                className="h-5 w-5 text-gray-500 hover:cursor-pointer"
                                onClick={() => {
                                  if (
                                    responseSizeStates.individualStates[
                                      index
                                    ] === "shortened" &&
                                    shortMessages !== null
                                  ) {
                                    handleCreateChatThread(
                                      index,
                                      shortMessages,
                                    );
                                  } else {
                                    handleCreateChatThread(index, messages);
                                  }
                                }}
                              />
                            )}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <p className="truncate text-sm text-gray-900 font-semibold whitespace-pre-wrap">
                          {`${index + 1}. ` + question.content}
                        </p>
                      </div>

                      <div className="mt-2 text-sm text-gray-900">
                        {messages !== null && answer !== null && (
                          <DealQuestionThreadMarkdown
                            orgId={orgId}
                            dealId={dealId}
                            messages={messages}
                            index={index}
                            content={answer.content}
                            shortContent={
                              shortAnswer !== null
                                ? shortAnswer.content
                                : answer.content
                            }
                            citationInstances={answer.citationInstances}
                            setFileViewerParams={setFileViewerParams}
                            responseSizeState={
                              responseSizeStates.individualStates[index]
                            }
                          />
                        )}
                        {answer === null &&
                          responseStatus === "pending" &&
                          "Loading..."}
                        {answer === null &&
                          responseStatus === "failed" &&
                          "Sorry, an error has occured."}
                      </div>
                    </div>
                  </div>
                </li>
              );
            },
          )}
        </ul>
      </div>
    </div>
  );
};

export default DealQuestionWindow;
