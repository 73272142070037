import { useAuth0 } from "@auth0/auth0-react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface Props {
  children: ReactElement;
}

export default function UnverifiedRoute({ children }: Props) {
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/authenticate" />;
  }

  if (user?.email_verified === true) {
    return <Navigate to="/" />;
  }

  return children;
}
