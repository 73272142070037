import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import {
  createWebsite,
  readWebsite,
  deleteWebsite,
  listWebsites,
  WebsiteCreate,
} from "../api/websites";

export const useWebsitesQuery = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "websites"],
    queryFn: () => listWebsites(user, orgId, dealId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.some((file) => file.ingestion_status === "pending")) {
        return 10000;
      }
      return false;
    },
  });
};

export const useWebsiteQuery = (
  orgId: string,
  dealId: string,
  websiteId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "websites", websiteId],
    queryFn: () => readWebsite(user, orgId, dealId, websiteId),
    enabled: !!user,
    retry: defaultRetryFunc,
    refetchInterval: (data) => {
      if (data && data.ingestion_status === "pending") {
        return 10000;
      }
      return false;
    },
  });
};

export const useCreateWebsiteMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: (data: WebsiteCreate) =>
      createWebsite(user, orgId, dealId, data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "websites"],
      });
    },
  });
};

export const useDeleteWebsiteMutation = (orgId: string, dealId: string) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  assertUserIsAuthenticated(user);
  return useMutation({
    mutationFn: ({ websiteId }: { websiteId: string }) =>
      deleteWebsite(user, orgId, dealId, websiteId),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: [orgId, "deals", dealId, "websites"],
      });
    },
  });
};
