import { useParams } from "react-router-dom";
import TabLayout from "../TabLayout";
import CreateDealButton from "./CreateDealsButton";
import { DealsList } from "./DealsList";

type URLParams = {
  orgId: string;
};

export default function DealsTab() {
  const { orgId } = useParams() as URLParams;

  return (
    <TabLayout
      items={[{ name: "Deals", href: null }]}
      buttons={[<CreateDealButton orgId={orgId} key={orgId} />]}
    >
      <div className="pt-4 px-4">
        <div className="flex">
          <p className="text-sm max-w-2xl text-gray-700">
            Deals contain all information related to the purchase of a
            particular company. You can upload documents, apply question
            templates, and chat with your knowledge base inside a deal.
          </p>
        </div>
        <DealsList orgId={orgId} />
      </div>
    </TabLayout>
  );
}
