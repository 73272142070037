import { useAuth, defaultRetryFunc } from "./shared";
import { assertUserIsAuthenticated } from "../utils";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { readDealFileTable, listDealFilesTables } from "../api/dealFileTables";

export const useDealFileTablesQuery = (
  orgId: string,
  dealId: string,
  fileId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useInfiniteQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId, "tables"],
    queryFn: ({ pageParam = "" }) =>
      listDealFilesTables(user, orgId, dealId, fileId, pageParam),
    enabled: !!user,
    retry: defaultRetryFunc,
    getNextPageParam: (lastPage) => lastPage.nextCursor.value,
  });
};

export const useDealFileTable = (
  orgId: string,
  dealId: string,
  fileId: string,
  fileTableId: string,
) => {
  const { user } = useAuth();
  assertUserIsAuthenticated(user);
  return useQuery({
    queryKey: [orgId, "deals", dealId, "files", fileId, "tables", fileTableId],
    queryFn: () => readDealFileTable(user, orgId, dealId, fileId, fileTableId),
    enabled: !!user,
    retry: defaultRetryFunc,
  });
};
