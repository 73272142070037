import { Button } from "@capsa-ai/capsa-ui";
import { Icon } from "@capsa-ai/capsa-ui";
import { Modal } from "@capsa-ai/capsa-ui";
import { Text } from "@capsa-ai/capsa-ui";
import SharepointBrowser from "@/features/sharepoint/components/SharepointBrowser";
import { useCreateSyncJobMutation } from "@/features/sharepoint/hooks/syncJobs";
import { useSharepointStore } from "@/features/sharepoint/store/useSharepointStore";
import React, { FC } from "react";

type Props = {
  handleImport?: (selected: { folders: string[]; files: string[] }) => void;
};

const SyncSharepointModal: FC<Props> = ({ handleImport }) => {
  const {
    modalOpen,
    closeModal,
    selectedIds,
    selectedFileIds,
    selectedFolderIds,
  } = useSharepointStore();
  const { mutate, isLoading, isError } = useCreateSyncJobMutation();

  const handleSync = () => {
    if (handleImport) {
      handleImport({
        folders: selectedFolderIds,
        files: selectedFileIds,
      });
      closeModal();
    } else {
      mutate(
        {
          externalFolderIds: selectedFolderIds,
          externalFileIds: selectedFileIds,
        },
        { onSuccess: () => closeModal() },
      );
    }
  };

  return (
    <Modal
      heading="Import Sharepoint Files"
      description="Choose sharepoint files or folders to import to the deal."
      isOpen={modalOpen}
      onClose={closeModal}
    >
      <SharepointBrowser />
      <div className="flex justify-between items-center">
        <div className="flex gap-1 items-center">
          <Icon type="sharepoint" size="lg" />
          {isError ? (
            <Text color="danger">There was an error, please try again.</Text>
          ) : (
            <Text color="label">Syncing from Sharepoint</Text>
          )}
        </div>
        <Button
          onClick={handleSync}
          size="lg"
          loading={isLoading}
          disabled={selectedIds.length === 0}
        >
          Import
        </Button>
      </div>
    </Modal>
  );
};

export default SyncSharepointModal;
