import { CheckIcon } from "@heroicons/react/20/solid";

export function CheckBox({
  state,
  onClick,
}: {
  state: "checked" | "unchecked" | "indeterminate";
  onClick: () => void;
}) {
  if (state === "checked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded items-center justify-center bg-indigo-600 hover:cursor-pointer"
      >
        <CheckIcon className="h-3 w-3 text-white" />
      </div>
    );
  }
  if (state === "unchecked") {
    return (
      <div
        onClick={() => onClick()}
        className="flex h-4 w-4 rounded border border-1 bg-white border-gray-300 items-center justify-center hover:cursor-pointer"
      />
    );
  }
  return (
    <div
      onClick={() => onClick()}
      className="flex h-4 w-4 rounded border border-1 bg-white border-gray-300 items-center justify-center hover:cursor-pointer"
    >
      <div className="bg-indigo-600 h-2 w-2 rounded-sm" />
    </div>
  );
}
