import { User } from "../types";
import { baseURL } from "./shared";
import { readTableAggregation } from "./tableAggregations";

export interface TableAggregationExportCreate {
  aggregated_table_indices: number[] | null;
}

function downloadFromURL(url: string, fileName: string) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

export const createTableAggregationExport = async (
  user: User,
  orgId: string,
  dealId: string,
  tableAggregationId: string,
  data: TableAggregationExportCreate,
) => {
  const response = await fetch(
    `${baseURL}/orgs/${orgId}/deals/${dealId}/table-aggregations/${tableAggregationId}/exports`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
      body: JSON.stringify(data),
    },
  );
  const tableAggregation = await readTableAggregation(
    user,
    orgId,
    dealId,
    tableAggregationId,
  );
  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    downloadFromURL(
      url,
      (tableAggregation.name === null ? "Unnamed" : tableAggregation.name) +
        " Tables Export" +
        ".xlsx",
    );
  } else {
    throw new Error(`${response.status}`);
  }
};
