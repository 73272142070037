import { useState } from "react";
import { useParams } from "react-router-dom";
import { Wizard } from "react-use-wizard";
import { z } from "zod";
import { useDealQuery } from "../../../../hooks/deals";
import TabLayout from "../../../TabLayout";
import ArrangeTablesPage from "./ArrangeTablesPage";
import SelectFilesPage, { SelectedFile } from "./SelectedFilesPage";

const urlParamsSchema = z.object({
  orgId: z.string(),
  dealId: z.string(),
});

export default function TableAggregationsCreate() {
  const params = useParams();
  const { orgId, dealId } = urlParamsSchema.parse(params);
  const dealQuery = useDealQuery(orgId, dealId);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  return (
    <TabLayout
      items={[
        { name: "Deals", href: `/orgs/${orgId}/deals` },
        {
          name: dealQuery.data?.name || "...",
          href: `/orgs/${orgId}/deals/${dealId}`,
        },
        {
          name: "Table Aggregations",
          href: `/orgs/${orgId}/deals/${dealId}/table-aggregations`,
        },
        { name: "Create", href: null },
      ]}
    >
      <div className="p-4">
        <Wizard>
          <SelectFilesPage
            orgId={orgId}
            dealId={dealId}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
          <ArrangeTablesPage
            orgId={orgId}
            dealId={dealId}
            selectedFiles={selectedFiles}
          />
        </Wizard>
      </div>
    </TabLayout>
  );
}
