import FileUploadComponent from "@/components/DealsTab/subcomponents/UploadDocuments";
import { Button } from "@capsa-ai/capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import { sendEvent } from "@/lib/posthog";
import React, { useEffect } from "react";

const TabFiles = ({
  onCreateDeal,
  creatingDeal,
}: {
  onCreateDeal: () => void;
  creatingDeal: boolean;
}) => {
  const { previousTab, setFiles } = useCreateCompanyStore();

  useEffect(() => {
    sendEvent("viewed_files_tab", {
      feature: "create_company",
    });
  }, []);

  const handleFiles = (files: FileList) => {
    setFiles(files);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <FileUploadComponent onFileUpload={handleFiles} />
      </div>
      <div className="flex gap-1.5">
        <Button
          variant="secondary"
          iconLeft="chevronLeft"
          onClick={previousTab}
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button
          iconRight="chevronRight"
          onClick={onCreateDeal}
          loading={creatingDeal}
        >
          Create Deal
        </Button>
      </div>
    </>
  );
};

export default TabFiles;
