import React, { useEffect, useState } from "react";
import { Button } from "@capsa-ai/capsa-ui";
import { useCreateCompanyStore } from "@/features/companies/store/useCreateCompanyStore";
import RegistrySearch from "@/features/registrySearch/pages/RegistrySearch";
import { sendEvent } from "@/lib/posthog";

const TabRegistrySearch = () => {
  const {
    nextTab,
    previousTab,
    resetLegalEntity,
    companyName,
    setLegalEntity,
    setCompanySuggestion,
    setRegistryDocuments,
  } = useCreateCompanyStore();

  const [documentsLoading, setDocumentsLoading] = useState(false);

  useEffect(() => {
    sendEvent("viewed_registry_tab", {
      feature: "create_company",
    });
  }, []);

  const onSkip = () => {
    resetLegalEntity();
    setRegistryDocuments([]);
    nextTab();
  };

  return (
    <>
      <RegistrySearch
        initialSearchValue={companyName}
        onLegalEntityChange={setLegalEntity}
        onCompanySuggestionChange={setCompanySuggestion}
        onDocumentsChange={setRegistryDocuments}
        onLoading={setDocumentsLoading}
      />
      <div className="flex gap-1.5">
        <Button
          variant="secondary"
          iconLeft="chevronLeft"
          onClick={previousTab}
        >
          Back
        </Button>
        <div className="flex-1" />
        <Button variant="secondary" onClick={onSkip}>
          Skip
        </Button>
        <Button
          iconRight="chevronRight"
          onClick={nextTab}
          disabled={documentsLoading}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default TabRegistrySearch;
