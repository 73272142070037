import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { combine } from "zustand/middleware";
import { subscribeWithSelector } from "zustand/middleware";
import { Button } from "@capsa-ai/capsa-ui";

const initialState = {
  modalOpen: false,
  sharepointEnabled: false,
  selectedIds: [] as string[],
  expandedIds: [] as string[],
  fileIds: [] as string[],
  folderIds: [] as string[],
  selectedFileIds: [] as string[],
  selectedFolderIds: [] as string[],
};

export const useSharepointStore = create(
  devtools(
    subscribeWithSelector(
      combine(initialState, (set, get) => ({
        reset: () => set(initialState),
        setSharepointEnabled: (enabled: boolean) =>
          set({ sharepointEnabled: enabled }),
        openModal: () => set({ modalOpen: true }),
        closeModal: () => set({ modalOpen: false }),
        setSelectedIds: (ids: string[]) => set({ selectedIds: ids }),
        setExpandedIds: (ids: string[]) => set({ expandedIds: ids }),
        setFileIds: (fileIds: string[]) => set({ fileIds }),
        setFolderIds: (folderIds: string[]) => set({ folderIds }),
        setSelectedFileIds: (fileIds: string[]) =>
          set({ selectedFileIds: fileIds }),
        setSelectedFolderIds: (folderIds: string[]) =>
          set({ selectedFolderIds: folderIds }),
        SharepointImportButton: () => {
          const { openModal, sharepointEnabled } = get() as any; // eslint-disable-line
          if (!sharepointEnabled) return null;
          return (
            <Button
              key="sharepoint-button"
              onClick={openModal}
              variant="secondary"
              iconLeft="sharepoint"
            >
              Import from Sharepoint
            </Button>
          );
        },
      })),
    ),
    {
      name: "SharepointStore",
    },
  ),
);

useSharepointStore.subscribe(
  (state) => state.selectedIds,
  (selectedIds) => {
    const { setSelectedFileIds, setSelectedFolderIds, folderIds, fileIds } =
      useSharepointStore.getState();

    setSelectedFolderIds(
      folderIds.filter((id) => Array.from(selectedIds).includes(id)),
    );
    setSelectedFileIds(
      fileIds.filter((id) => Array.from(selectedIds).includes(id)),
    );
  },
);
