import { EventFeatureName, EventName } from "@/types/analytics";
import posthog, { CaptureOptions } from "posthog-js";

type EventProperties = {
  feature?: EventFeatureName;
};

export const sendEvent = (
  name: EventName,
  properties?: EventProperties,
  options?: CaptureOptions,
) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  if (posthog) {
    posthog.capture(name, properties, options);
  } else {
    console.warn("PostHog is not initialized");
  }
};
