import { UsersIcon } from "@heroicons/react/24/outline";
import { APIError } from "../../../api/shared";
import { useOrgUsersQuery } from "../../../hooks";
import Table from "../../Table";
import DeleteOrgUserButton from "./DeleteOrgUserButton";
import UpdateOrgUserButton from "./UpdateOrgUserButton";

interface Props {
  orgId: string;
}

export default function OrgUsersList({ orgId }: Props) {
  const orgUsersQuery = useOrgUsersQuery(orgId);

  // Insufficient permissions
  if (
    orgUsersQuery.error instanceof APIError &&
    orgUsersQuery.error.type === "PermissionError"
  ) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">
          {"You don't have permission to list Org Users."}
        </p>
      </ul>
    );
  }

  // Error
  if (orgUsersQuery.isError) {
    return (
      <ul className="divide-y divide-gray-200 border-y-[1px] border-gray-200 my-4">
        <p className="py-4 text-sm text-gray-500">Error listing Org Users.</p>
      </ul>
    );
  }

  let data: React.ReactElement[][] = [];
  if (!orgUsersQuery.isLoading) {
    data = orgUsersQuery.data.map((orgUser) => {
      return [
        <p key={`email-${orgUser.id}`}>{orgUser.email}</p>,
        <p key={`roles-${orgUser.id}`}>
          {orgUser.roles.map((role) => role.role).join(", ")}
        </p>,
        <div
          key={`actions-${orgUser.id}`}
          className="flex flex-grow space-x-1 justify-end"
        >
          <UpdateOrgUserButton orgId={orgId} userId={orgUser.id} />
          <DeleteOrgUserButton orgId={orgId} user={orgUser} />
        </div>,
      ];
    });
  }

  return (
    <Table
      columnHeaders={["Email", "Roles", ""]}
      data={data}
      noDataIcon={UsersIcon}
      noDataMessage="You don't have any users in your organisation yet."
      isLoading={orgUsersQuery.isLoading}
    />
  );
}
