import { UseQueryResult } from "@tanstack/react-query";
import { User } from "./types";
import * as pdfjs from "pdfjs-dist";

export function assertUserIsAuthenticated(
  user: User | null,
): asserts user is User {
  if (user === null) throw new Error("User not authenticated.");
}

type HasPermissionResult = ["loading", "unknown", "true", "false"][number];

export function hasPermission(
  permissionsQuery: UseQueryResult<Set<string>, unknown>,
  permission: string,
): HasPermissionResult {
  if (permissionsQuery.isLoading) {
    return "loading";
  }
  if (permissionsQuery.isError) {
    return "unknown";
  }
  return permissionsQuery.data.has(permission) ? "true" : "false";
}

export async function checkForPassword(file: File) {
  if (file.type !== "application/pdf") {
    return null;
  }
  const arrayBuffer = await file.arrayBuffer();
  const documentLoadingTask = pdfjs.getDocument(arrayBuffer);
  let password: string | null = null;
  documentLoadingTask.onPassword = (
    callback: (password: string) => void,
    reason: number,
  ) => {
    if (reason === pdfjs.PasswordResponses.NEED_PASSWORD) {
      password = prompt(
        `"${file.name}" is password protected, please enter password to upload.`,
      );
    } else if (reason === pdfjs.PasswordResponses.INCORRECT_PASSWORD) {
      password = prompt(
        `Incorrect password for "${file.name}", please try again.`,
      );
    }
    if (password !== null) {
      callback(password);
    } else {
      throw new Error("Password prompt cancelled.");
    }
  };
  await documentLoadingTask.promise;
  return password;
}
