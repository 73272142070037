import { User } from "../types";
import { baseURL, resolveFetch } from "./shared";
import OpenAI from "openai";

export interface DealUserChatThreadList {
  id: string;
  org_id: string;
  deal_id: string;
  name: string | null;
  response_status: "pending" | "completed" | "failed";
  created_at: string;
}

export const listDealUserChatThreads = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DealUserChatThreadList[]> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/users/current/chat-threads`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

interface DealChatThreadList extends DealUserChatThreadList {
  user_email: string | null;
}

// For admins only
export const listDealChatThreads = async (
  user: User,
  orgId: string,
  dealId: string,
): Promise<DealChatThreadList[]> => {
  return await resolveFetch(
    fetch(`${baseURL}/orgs/${orgId}/deals/${dealId}/chat-threads`, {
      headers: {
        Authorization: `Bearer ${await user.getIdToken()}`,
      },
    }),
  );
};

export interface SearchDealFilesOptions {
  file_ids: string[] | null;
  website_ids: string[] | null;
  webpage_ids: string[] | null;
}

export interface ChatMessageThread {
  messages: OpenAI.Chat.ChatCompletionMessageParam[];
  search_deal_files_options: SearchDealFilesOptions;
}

export interface ChatMessageThreadCreate {
  messages: OpenAI.Chat.ChatCompletionMessageParam[];
  search_deal_files_options?: SearchDealFilesOptions;
}

export interface DealUserChatThreadRead {
  id: string;
  org_id: string;
  deal_id: string;
  name: string | null;
  thread: ChatMessageThread;
  response_status: "pending" | "completed" | "failed";
  created_at: string;
}

export const readDealUserChatThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
): Promise<DealUserChatThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/users/current/chat-threads/${dealChatThreadId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

interface DealChatThreadRead extends DealUserChatThreadRead {
  user_email: string | null;
}

// For admins only
export const readDealChatThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
): Promise<DealChatThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/chat-threads/${dealChatThreadId}`,
      {
        headers: {
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};

export interface DealUserChatThreadCreate {
  thread: ChatMessageThreadCreate;
}

export const createDealUserChatThread = async (
  user: User,
  orgId: string,
  dealId: string,
  data: DealUserChatThreadCreate,
): Promise<DealUserChatThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/users/current/chat-threads`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};

export interface DealUserChatThreadUpdate {
  name: string | null;
  thread: ChatMessageThreadCreate | null;
}

export const updateDealUserChatThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
  data: DealUserChatThreadUpdate,
): Promise<DealUserChatThreadRead> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/users/current/chat-threads/${dealChatThreadId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
        body: JSON.stringify(data),
      },
    ),
  );
};

export const deleteDealUserChatThread = async (
  user: User,
  orgId: string,
  dealId: string,
  dealChatThreadId: string,
): Promise<null> => {
  return await resolveFetch(
    fetch(
      `${baseURL}/orgs/${orgId}/deals/${dealId}/users/current/chat-threads/${dealChatThreadId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await user.getIdToken()}`,
        },
      },
    ),
  );
};
